import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Modal, Button, message, notification } from "antd";
import { useTranslation } from "react-i18next";
import { DeleteOutlined, CheckOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

import { deleteEvent } from "../../../../Admin/AdminEvents/deleteEvent";
import { getDraftEvents } from "./getDraftEvents";
import { getPastEvents } from "./getPastEvents";
import { eventFormStore } from "../eventFormStore";
import { pageStore } from "../../../../../store/pageStore/pageStore";
import { getPictureUrl } from "../../../../../helpers/picture/getPictureUrl";
import { CustomSpinner } from "../../../../../components/CustomSpinner/CustomSpinner";
import { addEvent } from "../../../../Admin/AdminEvents/addEvent";

import "./EventFormDraftModal.less";

export const EventFormDraftModal = observer((props) => {
  const { t } = useTranslation();
  const { eventtypesOptions } = props;
  const [drafts, setDrafts] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);
  const [usePastEvents, setUsePastEvents] = useState(false);
  const [draftIdToDelete, setDraftIdToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(null);

  const fetchDrafts = async () => {
    setIsLoading(true);
    const drafts = await getDraftEvents();
    setDrafts(drafts);
    eventFormStore.setShowDraftModal(!!drafts.length);
    setIsLoading(false);
  };

  const fetchPastsEvents = async () => {
    setIsLoading(true);
    const pastEvents = await getPastEvents();
    setPastEvents(pastEvents);
    eventFormStore.setShowDraftModal(!!pastEvents.length);
    setIsLoading(false);
  };

  const deleteDraftHandler = (id) => {
    setDraftIdToDelete(id);
    setTimeout(() => {
      setDraftIdToDelete(null);
    }, "3000");
  };

  const confirmDeleteDraftHandler = async () => {
    if (draftIdToDelete) {
      await deleteEvent(draftIdToDelete);
      message.info("Draft deleted!");
      fetchDrafts();
    }
  };

  const getUrlsFromPicturePath = async (pictures) => {
    const urls = await Promise.all(
      pictures.map((picture) => {
        return getPictureUrl(picture, "events");
      }),
    );
    return urls;
  };

  const selectDraftHandler = async (draft, isCopy) => {
    setIsLoading(true);
    if (isCopy) {
      const dataObject = {
        eventtype: draft.eventtype,
        title: draft.title,
        description: draft.description,
        location: draft.locationId,
        locationName: draft.locationName,
        locationAddress: draft.locationAddress,
        locationCoordinates: draft.locationCoordinates,
        pictures: draft.artworks,
        eventTags: draft.eventTags,
        lineUp: draft.lineUp,
        links: draft.links,
        fromDate: draft.fromDate?.valueOf(),
        untilDate: draft.untilDate?.valueOf(),
        hasDresscode: draft.hasDresscode,
        dresscodeDoTags: draft.dresscodeDoTags,
        dresscodeDontTags: draft.dresscodeDontTags,
        ageMin: draft.ageMin,
        prices: JSON.stringify(draft.prices),
        equipment: draft.equipment,
        isPrivate: draft.isPrivate,
        forwardable: draft.forwardable,
      };
      try {
        const res = await addEvent(dataObject);
        eventFormStore.setEventId(res.id);
        message.success("Draft saved!");
      } catch (e) {
        notification.error({
          message: "Error!",
          description: e.toString(),
          duration: 0,
          placement: "bottomRight",
          className: "customNotification",
        });
      }
    } else {
      eventFormStore.setEventId(draft.id);
    }
    eventFormStore.setIsDraft(draft.isDraft);
    eventFormStore.setValidated(draft.validated);
    eventFormStore.setEquipment(draft.equipment);
    eventFormStore.setTitle(draft.title);
    eventFormStore.setEventtype(draft.eventtype);
    eventFormStore.setDescription(draft.description);
    eventFormStore.setLocationId(draft.location);
    eventFormStore.setLocationName(draft.locationName);
    eventFormStore.setLocationAddress(draft.locationAddress);
    eventFormStore.setArtworks(draft.pictures);
    eventFormStore.setEventTags(draft.eventTags);
    eventFormStore.setLineUp(draft.lineUp);
    eventFormStore.setLinks(draft.links);
    eventFormStore.setAgeMin(draft.ageMin);
    eventFormStore.setIsPrivate(draft.private);
    eventFormStore.setForwardable(draft.forwardable);
    eventFormStore.setHasDresscode(draft.hasDresscode);
    eventFormStore.setDresscodeDoTags(draft.dresscodeDoTags);
    eventFormStore.setDresscodeDontTags(draft.dresscodeDontTags);
    eventFormStore.setEquipment(draft.equipment);
    eventFormStore.setArtworksUrl(await getUrlsFromPicturePath(draft.pictures));
    draft.fromDate && eventFormStore.setFromDate(dayjs(draft.fromDate));
    draft.untilDate && eventFormStore.setUntilDate(dayjs(draft.untilDate));
    draft.prices.length && eventFormStore.setPrices(JSON.parse(draft.prices));
    setIsLoading(false);
    eventFormStore.setShowDraftModal(false);
  };

  useEffect(() => {
    if (eventFormStore.title === null) {
      fetchDrafts();
      fetchPastsEvents();
    }
  }, []);

  const draftElement =
    drafts.length > 0 &&
    drafts?.map((draft) => {
      const { id, title, eventtype, createdAt } = draft;
      const created = dayjs(createdAt).format("DD/MM/YYYY, HH:mm");
      const eventtypeString = eventtypesOptions.filter(
        (e) => e.value === eventtype,
      )[0].label;
      return (
        <div
          onClick={() => selectDraftHandler(draft)}
          className="draftmodal__element"
          key={id}
        >
          {draftIdToDelete === id && (
            <div
              className="darftmodal__confirmDeleteTextOver"
              onClick={(event) => {
                event.stopPropagation();
                confirmDeleteDraftHandler();
              }}
            >
              {t("eventform.clickConfirmDeletion")}?
            </div>
          )}
          <div className="draftmodal__titleinfo">
            <div className="draftmodal__eventTitle">{title}</div>
            <div className="draftmodal__info">
              {eventtypeString}, {t("eventform.addedThe")} {created}
            </div>
          </div>
          <DeleteOutlined
            className="draftmodal__delete"
            onClick={(event) => {
              event.stopPropagation();
              deleteDraftHandler(id);
            }}
          />
        </div>
      );
    });

  const pastElement =
    pastEvents.length > 0 &&
    pastEvents?.map((pastEvent) => {
      const { id, title, eventtype, createdAt, validated } = pastEvent;
      const created = dayjs(createdAt).format("DD/MM/YYYY, HH:mm");
      const eventtypeString = eventtypesOptions.filter(
        (e) => e.value === eventtype,
      )[0].label;
      return (
        <div
          onClick={() => selectDraftHandler(pastEvent, true)}
          className={`draftmodal__element ${!validated && "draftmodal__notValidated"}`}
          key={id}
        >
          <div className="draftmodal__titleinfo">
            <div className="draftmodal__eventTitle">{title}</div>
            <div className="draftmodal__info">
              {eventtypeString}, {t("eventform.addedThe")} {created}
            </div>
          </div>
          {validated && (
            <CheckOutlined
              className="draftmodal__check"
              onClick={(event) => {
                event.stopPropagation();
                deleteDraftHandler(id);
              }}
            />
          )}
        </div>
      );
    });

  return (
    <Modal
      title={
        <div className="draftmodal__title">
          {!drafts.length || usePastEvents
            ? t("eventform.copyPastEvent")
            : t("eventform.resumeDraft")}
          ?
        </div>
      }
      open={eventFormStore.showDraftModal}
      onCancel={() => eventFormStore.setShowDraftModal(false)}
      footer={
        <div className="draftmodal__footerContainer">
          <div className="draftmodal__footerOr">
            <div className="draftmodal__footerOrLine"></div>
            <div className="draftmodal__footerOrText">{t("general.or")}</div>
            <div className="draftmodal__footerOrLine"></div>
          </div>
          <Button
            onClick={() => eventFormStore.setShowDraftModal(false)}
            className="draftmodal__footerButton"
          >
            {t("eventform.createNewEvent")}
          </Button>
          {!!pastEvents.length && !!drafts.length && (
            <Button
              onClick={() => setUsePastEvents(!usePastEvents)}
              className="draftmodal__footerButtonSwitch"
            >
              {usePastEvents
                ? t("eventform.resumeDraft")
                : t("eventform.copyPastEvent")}
            </Button>
          )}
        </div>
      }
      centered={true}
      className={`eventform__draftModal ${pageStore.selectedTheme === "light" ? "backgroundLight" : "backgroundDark"}`}
    >
      {isLoading ? (
        <div className="draftmodal__loading">
          <CustomSpinner text={`${t("general.loading")} (Drafts)`} />
        </div>
      ) : !drafts.length || usePastEvents ? (
        <div className="draftmodal__select">{pastElement}</div>
      ) : (
        <div className="draftmodal__select">{draftElement}</div>
      )}
    </Modal>
  );
});
