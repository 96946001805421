import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { LoadingOutlined } from "@ant-design/icons";
import { useParams, useNavigate } from "react-router-dom";
import { message } from "antd";

import { AddArticleForm } from "./AddArticleForm/AddArticleForm";
import { blogArticleStore } from "./blogArticleStore";
import { ArticleDraftSelect } from "./ArticleDraftSelect/ArticleDraftSelect";
import { HelpButtons } from "../../../components/HelpButtons/HelpButtons";
import { authStore } from "../../../store/authStore/authStore";
import { userStore } from "../../../store/userStore/userStore";
import { CustomError } from "../../../components/CustomError/CustomError";
import { CustomSpinner } from "../../../components/CustomSpinner/CustomSpinner";

import "./EditBlogArticles.less";

export const EditBlogArticles = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const fetchDataAndSetArticle = async () => {
    await blogArticleStore.fetchData();
    if (params.articleid) {
      const selectedArticle = await blogArticleStore.blogArticles.filter(
        (article) => article.id === params.articleid,
      );
      if (selectedArticle.length) {
        blogArticleStore.setArticleSelected(selectedArticle[0]);
        blogArticleStore.setArticleTitle(selectedArticle[0].title);
        blogArticleStore.setArticleTeaser(selectedArticle[0].teaser);
        blogArticleStore.setArticleHtmlBody(selectedArticle[0].body);
        blogArticleStore.setArticleSelectedTags(selectedArticle[0].tags);
        blogArticleStore.setArticleChanged(false);
      } else {
        message.error(t("blogarticle.noAccess"));
        navigate("/editblogarticle/");
      }
    }
  };

  useEffect(() => {
    fetchDataAndSetArticle();
  }, [params.articleid]);

  const draftArticles = blogArticleStore.blogArticles
    .filter((article) => !article.isTemplate)
    .filter((article) => article.isDraft)
    .filter((article) => {
      if (userStore.partnerSelected) {
        return article.partnerId === parseInt(userStore.partnerSelected.id);
      } else {
        return (
          !article.partnerId | article.userCollaborator?.includes(userStore.id)
        );
      }
    })
    .map((article) => (
      <ArticleDraftSelect key={`draftArticle${article.id}`} article={article} />
    ));
  const pendingArticles = blogArticleStore.blogArticles
    .filter((article) => !article.isTemplate)
    .filter((article) => !article.isDraft)
    .filter((article) => {
      if (userStore.partnerSelected) {
        return article.partnerId === parseInt(userStore.partnerSelected.id);
      } else {
        return (
          !article.partnerId | article.userCollaborator?.includes(userStore.id)
        );
      }
    })
    .map((article) => (
      <ArticleDraftSelect
        key={`pendingArticle${article.id}`}
        article={article}
      />
    ));
  const publishedArticles = blogArticleStore.blogArticles
    .filter((article) => article.validated)
    .filter((article) => {
      if (userStore.partnerSelected) {
        return article.partnerId === parseInt(userStore.partnerSelected.id);
      } else {
        return (
          !article.partnerId | article.userCollaborator?.includes(userStore.id)
        );
      }
    })
    .map((article) => (
      <ArticleDraftSelect
        key={`publishedArticle${article.id}`}
        article={article}
      />
    ));
  const templateArticles = blogArticleStore.blogArticles
    .filter((article) => article.isTemplate)
    .filter((article) => !article.archived)
    .map((article) => (
      <ArticleDraftSelect
        key={`templateArticle${article.id}`}
        article={article}
        isTemplate={true}
      />
    ));

  return (
    <>
      {userStore.isBlogWritter ? (
        <div className="blogarticle__main">
          <div className="blogarticle__containerLeft">
            <div className="blogarticle__leftContainer">
              <div className="blogarticle__leftContainerTitle">
                {t("blogarticle.draftArticles")}
              </div>
              {blogArticleStore.isLoading ? (
                <LoadingOutlined />
              ) : draftArticles.length ? (
                draftArticles
              ) : (
                <div className="blogarticle__nothingYet">
                  {t("blogarticle.nothingYet")}
                </div>
              )}
            </div>
            {!blogArticleStore.isLoading && pendingArticles.length ? (
              <div className="blogarticle__leftContainer">
                <div className="blogarticle__leftContainerTitle">
                  {t("blogarticle.pendingPublication")}
                </div>
                {pendingArticles}
              </div>
            ) : null}
            {!blogArticleStore.isLoading && publishedArticles.length ? (
              <div className="blogarticle__leftContainer">
                <div className="blogarticle__leftContainerTitle">
                  {t("blogarticle.published")}
                </div>
                {publishedArticles}
              </div>
            ) : null}
            <div className="blogarticle__leftContainer">
              <div className="blogarticle__leftContainerTitle">
                {t("blogarticle.templates")}
              </div>
              {blogArticleStore.isLoading ? (
                <LoadingOutlined />
              ) : templateArticles.length ? (
                templateArticles
              ) : (
                <div className="blogarticle__nothingYet">
                  {t("blogarticle.nothingYet")}
                </div>
              )}
            </div>
          </div>
          <div className="blogarticle__containerCenter">
            <AddArticleForm />
          </div>
        </div>
      ) : userStore.isLoading ? (
        <div className="blogarticle__error">
          <CustomSpinner />
        </div>
      ) : (
        <div className="blogarticle__error">
          {authStore.hasAccess ? (
            <CustomError
              text={t("blogarticle.contactAdminToGetEditorRights")}
            />
          ) : (
            <CustomError text={t("general.pleaseLoginFirst")} />
          )}
        </div>
      )}
      <HelpButtons />
    </>
  );
});
