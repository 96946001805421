export const eventPageTourSteps = (ref1, ref2, ref3, ref4, ref5, ref6) => {
  return [
    {
      title: "Artwork of the event",
      description:
        "This is the artwork provided by the promoter. Click to enlarge.",
      placement: "right",
      target: () => ref1.current,
    },
    {
      title: "Name of the event",
      description: "This is the name of the event as provided by the promoter.",
      target: () => ref2.current,
    },
    {
      title: "Basic details of the event",
      description: `This are date of when the event will take place. Underneath is the type of event, and the location`,
      target: () => ref3.current,
    },
    {
      title: "Description of the event",
      description:
        "This is the name of the event as provided by the promoter. In case this text is long, it may be partically hidden. Click on 'Read more' to see it in full",
      placement: "top",
      target: () => ref6.current,
    },
    {
      title: "More details about the event",
      description:
        "Here are more information about the event: exact time at when the event take place, the prices applicable, and more. A list of tags describing with key words the event may also be available.",
      placement: "top",
      target: () => ref4.current,
    },
    {
      title: "Exact information about the event Location",
      description:
        "You will find here address and a link to google map pointing to the event location.",
      placement: "top",
      target: () => ref5.current,
    },
  ];
};
