export const ageOptions = [
  {
    value: 0,
    label: "No restriction",
  },
  {
    value: 18,
    label: "18+",
  },
  {
    value: 21,
    label: "21+",
  },
  {
    value: 30,
    label: "30+",
  },
];
