import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import {
  Input,
  Select,
  Space,
  Button,
  message,
  Popconfirm,
  Tooltip,
} from "antd";
import {
  DeleteOutlined,
  LoadingOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import Editor from "react-simple-wysiwyg";

import { blogArticleStore } from "../blogArticleStore.js";
import { nameParser } from "../../../../helpers/dev/nameParser.js";
import { pageStore } from "../../../../store/pageStore/pageStore.js";
import { addBlogArticle } from "./addBlogArticle.js";
import { updateBlogArticle } from "./updateBlogArticle.js";
import { deleteBlogArticle } from "./deleteBlogArticle.js";
import { userStore } from "../../../../store/userStore/userStore.js";
import { UserAvatar } from "../../../../components/UserAvatar/UserAvatar.jsx";
import { SelectCollaboratorModal } from "./SelectCollaboratorModal/SelectCollaboratorModal.jsx";
import { getUserPublicDataInBulk } from "./getUserPublicDataInBulk.js";

import "./AddArticleForm.less";
import { getPartnerDataInBulk } from "./getPartnerDataInBulk.js";

// TODO Outlet
// TODO Add media
// TODO Add article type (Text, PhotoSerie, Video, Podcast)
// TODO user notifications for collaboration
// TODO admin notifications for article pending publication
// TODO user notifications for article published
// TODO clean html body with https://www.npmjs.com/package/sanitize-html
// TODO show partner avatar in collab div if userStore.partnerSelected.id
// TODO message translation
// TODO on save, save the new Tags

export const AddArticleForm = observer(() => {
  const { t } = useTranslation();
  const { TextArea } = Input;
  const language = pageStore.selectedLanguage?.toLowerCase();
  const [showUserModal, setShowUserModal] = useState();
  const [loadingSelectedUser, setLoadingSelectedUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);
  const [authorDataLoading, setAuthorDataLoading] = useState(true);
  const [authorData, setAuthorData] = useState([]);

  const articleBodyChangeHandler = (e) => {
    const value = e.target.value;
    blogArticleStore.setArticleHtmlBody(value);
    blogArticleStore.setArticleChanged(true);
  };

  const teaserChangeHandler = (e) => {
    const value = e.target.value;
    blogArticleStore.setArticleTeaser(value);
    blogArticleStore.setArticleChanged(true);
  };

  const articleTitleChangeHandler = (e) => {
    const value = e.target.value;
    blogArticleStore.setArticleTitle(value);
    blogArticleStore.setArticleChanged(true);
  };

  const changeTagsHandler = async (value) => {
    blogArticleStore.setArticleSelectedTags(value);
    blogArticleStore.setArticleChanged(true);
  };

  const handleSaveDraft = async () => {
    try {
      const dataObject = {
        title: blogArticleStore.articleTitle,
        body: blogArticleStore.articleHtmlBody,
        teaser: blogArticleStore.articleTeaser,
        tags: blogArticleStore.articleSelectedTags,
      };
      if (blogArticleStore.articleSelected?.id) {
        await updateBlogArticle(
          blogArticleStore.articleSelected.id,
          dataObject,
        );
      } else {
        if (userStore.partnerSelected) {
          dataObject.partnerId = parseInt(userStore.partnerSelected.id);
        }
        const draftArticle = await addBlogArticle(dataObject);
        blogArticleStore.setArticleSelected(draftArticle);
      }
      blogArticleStore.fetchData();
      blogArticleStore.setArticleChanged(false);
      blogArticleStore.setTemplateSelected(false);
      message.success("Draft article saved!");
    } catch (e) {
      console.error(e);
    }
  };

  const publishHandler = async () => {
    try {
      const dataObject = {
        title: blogArticleStore.articleTitle,
        body: blogArticleStore.articleHtmlBody,
        teaser: blogArticleStore.articleTeaser,
        tags: blogArticleStore.articleSelectedTags,
        isDraft: false,
      };
      await updateBlogArticle(blogArticleStore.articleSelected.id, dataObject);
      message.success("Article has been submitted!");
      blogArticleStore.fetchData();
      blogArticleStore.setArticleChanged(false);
      blogArticleStore.setArticleHtmlBody(null);
      blogArticleStore.setArticleTags([]);
      blogArticleStore.setArticleTitle(null);
      blogArticleStore.setArticleTeaser(null);
      blogArticleStore.setTemplateSelected(false);
    } catch (e) {
      console.error(e);
    }
  };

  const handleDeleteDraft = async () => {
    await deleteBlogArticle(blogArticleStore.articleSelected.id);
    blogArticleStore.fetchData();
    blogArticleStore.setArticleChanged(false);
    blogArticleStore.setArticleHtmlBody(null);
    blogArticleStore.setArticleTags([]);
    blogArticleStore.setArticleTitle(null);
    blogArticleStore.setArticleTeaser(null);
    blogArticleStore.setArticleSelected(null);
    blogArticleStore.setTemplateSelected(false);
    message.error("Draft article deleted!");
  };

  const resetDraft = () => {
    blogArticleStore.setArticleChanged(false);
    blogArticleStore.setArticleHtmlBody(null);
    blogArticleStore.setArticleTags([]);
    blogArticleStore.setArticleTitle(null);
    blogArticleStore.setArticleTeaser(null);
    blogArticleStore.setArticleSelected(null);
    blogArticleStore.setTemplateSelected(false);
  };

  useEffect(() => {
    userStore.partnerSelected && resetDraft();
  }, [userStore.partnerSelected]);

  const articleTagsOptions = blogArticleStore.articleTags.map((tag) => {
    return {
      value: parseInt(tag.id),
      label: `${nameParser(tag.name, language)}${!tag.validated ? ` (${t("general.pendingReview")})` : ""}`,
    };
  });

  const fetchUserDataAvatar = async () => {
    try {
      const fetchUsersBulkData = await getUserPublicDataInBulk(
        blogArticleStore.articleSelected.userCollaborator,
      );
      setSelectedUser(fetchUsersBulkData);
      setLoadingSelectedUser(false);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (
      blogArticleStore.articleSelected &&
      blogArticleStore.articleSelected?.userCollaborator &&
      blogArticleStore.articleSelected?.userCollaborator.length
    ) {
      fetchUserDataAvatar();
    } else {
      setSelectedUser([]);
    }
  }, [blogArticleStore.articleSelected]);

  const fetchAuthorAvatar = async () => {
    try {
      let authorData;
      if (blogArticleStore.articleSelected.partnerId) {
        authorData = await getPartnerDataInBulk(
          blogArticleStore.articleSelected.partnerId,
        );
      } else {
        authorData = await getUserPublicDataInBulk(
          blogArticleStore.articleSelected.userId,
        );
      }
      setAuthorData(authorData[0]);
      setAuthorDataLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (
      blogArticleStore.articleSelected &&
      blogArticleStore.articleSelected?.userId
    ) {
      fetchAuthorAvatar();
    }
  }, [blogArticleStore.articleSelected]);

  const canManageCollaboratorAndPublish =
    userStore.id === parseInt(blogArticleStore.articleSelected?.userId) ||
    userStore.isAdmin;

  return (
    <>
      <SelectCollaboratorModal
        showModal={showUserModal}
        setShowModal={setShowUserModal}
        title={t("addarticleform.manageCollaborators")}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
      />
      <div className="addarticle__main">
        <div className="addarticle__headerContainer">
          <div className="addarticle__headerContainerLeft">
            {blogArticleStore.templateSelected
              ? "template"
              : t("addarticleform.newBlogArticle")}
          </div>
          <div className="addarticle__headerContainerRight">
            {blogArticleStore.articleSelected &&
              (!authorDataLoading ? (
                <UserAvatar
                  userName={authorData?.userName}
                  path={authorData?.avatar}
                  size={"small"}
                  clickable={false}
                  isPartner={blogArticleStore.articleSelected.partnerId}
                />
              ) : (
                <LoadingOutlined style={{ fontSize: "24px", padding: 4 }} />
              ))}
            {loadingSelectedUser ? (
              <LoadingOutlined style={{ fontSize: "24px", padding: 4 }} />
            ) : (
              selectedUser.map((user) => {
                return (
                  <UserAvatar
                    userName={user.userName}
                    path={user.avatar}
                    size={"small"}
                    clickable={false}
                    key={`collaboratorAvatar${user.id}`}
                  />
                );
              })
            )}
            {!loadingSelectedUser &&
            blogArticleStore.articleSelected &&
            canManageCollaboratorAndPublish ? (
              <Tooltip title={t("addarticleform.manageCollaborators")}>
                <div
                  className="addarticle__addCollab"
                  onClick={() => setShowUserModal(true)}
                >
                  <EditOutlined />
                </div>
              </Tooltip>
            ) : (
              <div className="addarticle__addCollabDisabled">
                <EditOutlined />
              </div>
            )}
          </div>
        </div>
        <Space
          direction="vertical"
          style={{
            display: "flex",
          }}
        >
          <Input
            placeholder={t("addarticleform.articleTitle")}
            onChange={articleTitleChangeHandler}
            value={blogArticleStore.articleTitle}
            className="addarticle__title"
          />
          <TextArea
            value={blogArticleStore.articleTeaser}
            placeholder={t("addarticleform.articleTeaser")}
            rows={1}
            maxLength={1024}
            onChange={teaserChangeHandler}
          />
          <Editor
            value={blogArticleStore.articleHtmlBody}
            onChange={articleBodyChangeHandler}
            placeholder={t("addarticleform.articleBody")}
            containerProps={{
              style: {
                resize: "vertical",
                height: "50vh",
                color: "black",
                background: "white",
                overflow: "scroll",
              },
            }}
          />

          <Select
            mode="tags"
            allowClear
            style={{ width: "100%", background: " " }}
            placeholder={t("addarticleform.pleaseSelectTags")}
            options={articleTagsOptions}
            onChange={changeTagsHandler}
            value={blogArticleStore.articleSelectedTags}
          />
        </Space>

        <div className="addarticle__buttonContainer">
          <div className="addarticle__buttonContainerLeft">
            {blogArticleStore.articleSelected && (
              <Popconfirm
                title={t("eventform.validateDelete")}
                onConfirm={handleDeleteDraft}
              >
                <Button className="addarticle__deleteDraftButton">
                  <DeleteOutlined />
                  {window.innerWidth > 675 && t("addarticleform.deleteDraft")}
                </Button>
              </Popconfirm>
            )}
          </div>
          <div className="addarticle__buttonContainerRight">
            <Button
              className={`addarticle__saveDraftButton ${pageStore.selectedTheme === "light" ? "lightColorTheme__Button" : "darkColorTheme__Button"}`}
              onClick={handleSaveDraft}
              disabled={!blogArticleStore.articleChanged}
            >
              {window.innerWidth > 675
                ? t("addarticleform.saveDraft")
                : t("general.save")}
            </Button>
            {blogArticleStore.articleSelected?.isDraft &&
              canManageCollaboratorAndPublish && (
                <Button
                  className="addarticle__publishButton"
                  onClick={publishHandler}
                >
                  {t("addarticleform.publish")}
                </Button>
              )}
          </div>
        </div>
      </div>
    </>
  );
});
