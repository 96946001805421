import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import {
  MailOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";

import { profileStore } from "../../../../../store/profileStore/profileStore";
import { userStore } from "../../../../../store/userStore/userStore";
import { partnerStore } from "../../../../../store/partnerStore/partnerStore";
import { authStore } from "../../../../../store/authStore/authStore";

import { acceptFriendRequest } from "./acceptFriendRequest";
import { addFollow } from "./addFollow";
import { addPartnerFollow } from "./addPartnerFollow";
import { addFriendRequest } from "./addFriendRequest";
import { deleteFollow } from "./deleteFollow";
import { deletePartnerFollow } from "./deletePartnerFollow";
import { deleteFriendRequest } from "./deleteFriendRequest";
import { deleteFriendship } from "./deleteFriendship";
import { isRequested } from "./isRequested";

import "./ProfileActions.less";

export const ProfileActions = observer((props) => {
  const { isPartner } = props;
  const { t } = useTranslation();
  const [isFollowing, setIsFollowing] = useState(null);
  const [isFriend, setIsFriend] = useState(null);
  const [isPending, setIsPending] = useState(null);

  useEffect(() => {
    setIsFollowing(
      isPartner
        ? !(
          partnerStore.followers.findIndex(
            (follower) => parseInt(follower.id) === userStore.id,
          ) < 0
        )
        : userStore.following
          ? !(
            userStore.following.findIndex(
              (following) => parseInt(following.id) === userStore.id,
            ) < 0
          )
          : false,
    );
    setIsFriend(
      userStore.friends
        ? !(
          userStore.friends.findIndex(
            (friend) => parseInt(friend.id) === userStore.id,
          ) < 0
        )
        : false,
    );
    setIsPending(
      userStore.friendrequests
        ? !(
          userStore.friendrequests.findIndex(
            (pending) => parseInt(pending.id) === userStore.id,
          ) < 0
        )
        : false,
    );
  }, [userStore.id]);

  const [requested, setRequested] = useState(false);

  const fetchRequested = async () => {
    const res = await isRequested(userStore.id);
    setRequested(res);
  };

  useEffect(() => {
    authStore.hasAccess && !isPartner && fetchRequested();
  }, []);

  const userName = isPartner ? partnerStore.userName : profileStore.userName;

  const handleClick = async (action) => {
    if (authStore.hasAccess) {
      try {
        if (isPartner) {
          if (action === "follow") {
            await addPartnerFollow(partnerStore.id);
            setIsFollowing(true);
          } else if (action === "unfollow") {
            await deletePartnerFollow(partnerStore.id);
            setIsFollowing(false);
          }
          partnerStore.fetchPartnerData(userName, false);
        } else {
          if (action === "follow") {
            await addFollow(profileStore.id);
            setIsFollowing(true);
          } else if (action === "unfollow") {
            await deleteFollow(profileStore.id);
            setIsFollowing(false);
          } else if (action === "request") {
            await addFriendRequest(profileStore.id);
            setIsPending(true);
          } else if (action === "unrequest") {
            await deleteFriendRequest(profileStore.id);
            setIsPending(false);
          } else if (action === "unfriend") {
            await deleteFriendship(profileStore.id);
            setIsPending(false);
            setIsFriend(false);
          } else if (action === "acceptrequest") {
            await acceptFriendRequest(profileStore.id);
            setIsPending(false);
            setRequested(false);
            setIsFriend(true);
          }
          profileStore.fetchProfileData(userName, false);
        }
      } catch (e) {
        console.log("error:", e);
      }
    }
  };

  // TODO2: add message functionality

  return (
    <>
      <div className="profil__actionContainer">
        <div className={"profil__actionDisabled"}>
          <MailOutlined /> {t("profile.sendMessage")}
        </div>
        {
          // Friend/UnFriend action
          !isPartner &&
          (requested ? (
            <div
              className={"profil__action"}
              onClick={() => handleClick("acceptrequest")}
            >
              <UserAddOutlined /> {t("profile.acceptfriendRequest")}
            </div>
          ) : isPending ? (
            <div
              className={"profil__action"}
              onClick={() => handleClick("unrequest")}
            >
              <UserDeleteOutlined /> {t("profile.deletePendingRequest")}
            </div>
          ) : isFriend ? (
            <div
              className={"profil__action"}
              onClick={() => handleClick("unfriend")}
            >
              <UserDeleteOutlined /> {t("profile.unfriendRequest")}
            </div>
          ) : (
            <div
              className={
                authStore.hasAccess
                  ? "profil__action"
                  : "profil__actionDisabled"
              }
              onClick={() => handleClick("request")}
            >
              <UserAddOutlined /> {t("profile.sendFriendRequest")}
            </div>
          ))
        }
        {
          // Follow/Unfollow action
          isFollowing ? (
            <div
              className={"profil__action"}
              onClick={() => handleClick("unfollow")}
            >
              <EyeInvisibleOutlined /> {t("profile.unfollow")} {userName}
            </div>
          ) : (
            <div
              className={
                authStore.hasAccess
                  ? "profil__action"
                  : "profil__actionDisabled"
              }
              onClick={() => handleClick("follow")}
            >
              <EyeOutlined /> {t("profile.follow")} {userName}
            </div>
          )
        }
      </div>
    </>
  );
});
