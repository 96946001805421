import React, { useState, useEffect } from "react";
import { Popconfirm, Table, Tooltip } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import { getAllPhotos } from "./getAllPhotos";
import { deletePhoto } from "./deletePhoto";
import { getAllPartnersName } from "./getAllPartnersName";
import { AdminCustomSpinner } from "../AdminCustomSpinner/AdminCustomSpinner";
import { getPictureUrl } from "../../../helpers/picture/getPictureUrl";

// TODO: toogleIsAdult and toggeIsPrivate
// TODO: Add tag management

export const AdminPhotos = () => {
  const [photos, setPhotos] = useState([]);
  const [photosUrls, setPhotosUrls] = useState([]);
  const [photosUrlsBlur, setPhotosUrlsBlur] = useState([]);
  const [partnerNames, setPartnerNames] = useState([]);

  const fetchAllPhotos = async () => {
    const results = await getAllPhotos();
    setPhotos(results);
  };

  const fetchAllPartnersName = async () => {
    const results = await getAllPartnersName();
    const names = {};
    results.map((name) => {
      names[name.id] = name.userName;
      return null;
    });
    setPartnerNames(names);
  };

  const fetchUrlsFromPicturePath = async () => {
    const urls = {};
    await Promise.all(
      photos.map(async (photo) => {
        if (!photo.photo) {
          return null;
        }
        const bucket = photo.partnerId ? "partners" : "users";
        urls[photo.id] = await getPictureUrl(`${photo.photo}_t`, bucket);
      }),
    );
    setPhotosUrls(urls);
  };

  const fetchUrlsBlurFromPicturePath = async () => {
    const urls = {};
    await Promise.all(
      photos.map(async (photo) => {
        if (!photo.photo) {
          return null;
        }
        const bucket = photo.partnerId ? "partners" : "users";
        urls[photo.id] = await getPictureUrl(`${photo.photo}_b`, bucket);
      }),
    );
    setPhotosUrlsBlur(urls);
  };

  useEffect(() => {
    fetchAllPhotos();
    fetchAllPartnersName();
  }, []);

  useEffect(() => {
    fetchUrlsFromPicturePath();
    fetchUrlsBlurFromPicturePath();
  }, [photos]);

  const deletePhotoHandler = async (id) => {
    await deletePhoto(id);
    fetchAllPhotos();
  };

  const columns = [
    {
      title: "Thumb",
      dataIndex: "thumb",
      key: "thumb",
      width: "100px",
      render: (_, { id }) => {
        return (
          <img
            src={photosUrls[id]}
            width="100px"
            height="100px"
            style={{ objectFit: "cover" }}
          />
        );
      },
    },
    {
      title: "Blured",
      dataIndex: "blur",
      key: "blur",
      width: "100px",
      render: (_, { id }) => {
        return (
          <img
            src={photosUrlsBlur[id]}
            width="100px"
            height="100px"
            style={{ objectFit: "cover" }}
          />
        );
      },
    },
    {
      title: "Uploader",
      dataIndex: "uploader",
      key: "uploader",
      render: (_, { partnerId, userId, user }) => {
        return (
          <>
            {partnerId ? (
              <>
                <div style={{ fontSize: "10px", color: "#999" }}>
                  Partner #{partnerId}
                </div>
                {
                  <Link to={`../partner/${partnerNames[partnerId]}`}>
                    {partnerNames[partnerId]}
                  </Link>
                }
              </>
            ) : (
              <>
                <div style={{ fontSize: "10px", color: "#999" }}>
                  User #{userId}
                </div>
                <Link to={`../user/${user.userName}`}>{user.userName}</Link>
              </>
            )}
          </>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (_, { id, description }) => (
        <>
          <div style={{ fontSize: "10px", color: "#999" }}>Photo #{id}</div>
          <div>{description}</div>
        </>
      ),
    },
    {
      title: "Comments",
      dataIndex: "comments",
      key: "comments",
      align: "center",
      sorter: (a, b) => a.comments - b.comments,
      render: (_, { comments }) => comments?.length,
    },
    {
      title: "Likes",
      dataIndex: "likes",
      key: "likes",
      align: "center",
      sorter: (a, b) => a.likes - b.likes,
      render: (_, { likes }) => likes?.length,
    },
    {
      title: "Adult",
      dataIndex: "isAdult",
      key: "isAdult",
      align: "center",
      sorter: (a, b) => a.isAdult - b.isAdult,
      render: (_, { id, isAdult }) => {
        return (
          <Tooltip title="Double click to toggle this value">
            <div
              style={{ cursor: "pointer" }}
              onDoubleClick={() => toogleIsAdult(id, !isAdult)}
            >
              {isAdult ? (
                "🍆"
              ) : (
                <span style={{ filter: "grayscale(1)", opacity: 0.25 }}>
                  🍆
                </span>
              )}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: "Private",
      dataIndex: "isPrivate",
      key: "isPrivate",
      align: "center",
      sorter: (a, b) => a.isPrivate - b.isPrivate,
      render: (_, { id, isPrivate }) => {
        return (
          <Tooltip title="Double click to toggle this value">
            <div
              style={{ cursor: "pointer" }}
              onDoubleClick={() => toogleIsPrivate(id, !isPrivate)}
            >
              {isPrivate ? (
                "🥷"
              ) : (
                <span style={{ filter: "grayscale(1)", opacity: 0.15 }}>
                  🥷
                </span>
              )}
            </div>
          </Tooltip>
        );
      },
    },
    {
      title: " ",
      dataIndex: "edit",
      align: "center",
      width: "80px",
      render: (_, record) => {
        return (
          <span>
            <Tooltip title="Delete Photo">
              <Popconfirm
                title="Sure to delete this photo?"
                style={{ marginRight: 8 }}
                onConfirm={() => deletePhotoHandler(record.id)}
              >
                <DeleteOutlined className="admin__editLogo" />
              </Popconfirm>
            </Tooltip>
          </span>
        );
      },
    },
  ];

  return (
    <div>
      {photos.length === 0 ? (
        <div className="admin__centered">
          <AdminCustomSpinner text="Loading Photos" />
        </div>
      ) : (
        <>
          <Table
            className="admin__table"
            dataSource={photos}
            columns={columns}
            pagination={false}
            size="small"
            scroll={{
              x: 1000,
            }}
          />
        </>
      )}
    </div>
  );
};
