import React, { useState, useEffect } from "react";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import {
  EyeInvisibleOutlined,
  LockOutlined,
  CloseOutlined,
} from "@ant-design/icons";

import { getPictureUrl } from "../../../../../../helpers/picture/getPictureUrl";
import { CustomSpinner } from "../../../../../../components/CustomSpinner/CustomSpinner";
import { pageStore } from "../../../../../../store/pageStore/pageStore";
import { authStore } from "../../../../../../store/authStore/authStore";

import "./PhotoCard.less";

const DESC_LENGTH_IN_CARACTERS = 30;

// TODO: archive photo
// TODO: edit phot
// TODO: Translations

export const PhotoCard = (props) => {
  const { photo, description, isPrivate, isAdult } = props.photo;
  const [pictureUrl, setPictureUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const { t } = useTranslation();

  const showBlur = isPrivate ? true : !!(isAdult && !authStore.hasAccess);

  const loadPictureUrl = async () => {
    try {
      setPictureUrl(null);
      if (photo) {
        const photoPath = `${photo}_${showBlur ? "b" : "t"}`;
        const bucket = props.isPartner ? "partners" : "users";
        const url = await getPictureUrl(photoPath, bucket);
        const isloaded = new Promise((resolve, reject) => {
          const loadImg = new Image();
          loadImg.src = url;
          loadImg.onload = () => resolve(url);
          loadImg.onerror = (err) => reject(err);
        });
        await isloaded;
        setPictureUrl(url);
      }
    } catch (e) {
      setHasError(true);
      console.error(e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadPictureUrl();
  }, []);

  const descFormated = isPrivate
    ? t("general.private")
    : description.length > DESC_LENGTH_IN_CARACTERS
      ? `${description.substring(0, DESC_LENGTH_IN_CARACTERS)}...`
      : description;

  const photoClickHandler = () => {
    pageStore.setPictureSelected(props.index);
    pageStore.setShowOverlayGallery(true);
  };

  return (
    <div className="photoCard__container">
      <Tooltip title={isLoading ? "loading" : descFormated}>
        {!isLoading && isPrivate && (
          <div className="photoCard__logoOver">
            <LockOutlined />
          </div>
        )}
        {!isLoading && isAdult && !authStore.hasAccess && (
          <div className="photoCard__logoOver">
            <EyeInvisibleOutlined />
          </div>
        )}
        {hasError && (
          <div className="photoCard__logoOver photoCard__error">
            <CloseOutlined />
            <span style={{ fontSize: 10, paddingTop: 4, letterSpacing: 3 }}>
              error
            </span>
          </div>
        )}
        <div
          className="photoCard__main"
          onClick={photoClickHandler}
          style={
            pictureUrl && {
              backgroundImage: "url(" + pictureUrl + ")",
              width: 100,
              height: 100,
            }
          }
        >
          {isLoading && (
            <div className="photoCard__loading">
              <CustomSpinner size="small" />
            </div>
          )}
        </div>
      </Tooltip>
    </div>
  );
};
