import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { LoginForm } from "../../components/LoginForm/LoginForm";
import { SignUpForm } from "../../components/SignUpForm/SignUpForm";
import { AlreadyMember } from "./AlreadyMember";
import { authStore } from "../../store/authStore/authStore";
import { pageStore } from "../../store/pageStore/pageStore";

import "./LoginPage.less";
import { observer } from "mobx-react";

export const LoginPage = observer(() => {
  const navigate = useNavigate();

  useEffect(() => {
    if (authStore.hasAccess) {
      navigate("/");
    }
  });

  return (
    <div className="loginPage__container">
      <div
        className={`backgroundLogin ${pageStore.selectedTheme === "dark" ? "backgroundDark" : "backgroundLight"}`}
      ></div>
      <div className="overBackground"></div>
      <div className="login__container">
        <AlreadyMember />
        {pageStore.showLogin ? (
          <LoginForm />
        ) : (
          <SignUpForm inviteCode="earlybird2024" />
        )}
      </div>
    </div>
  );
});
