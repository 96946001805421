import React from "react";

export const spielplanTourSteps = (ref1, ref2, ref3) => {
  return [
    {
      title: "Filter result per time span",
      description:
        "You probably wants to see events for a specifc time frame: use this feature to filter the results per month, weeks or day.",
      placement: "right",
      target: () => ref1.current,
    },
    {
      title: "Location, events and tags",
      description: (
        <>
          Use this to filter the results based on Locations, Event types or
          Tags. Those filter are not additive. Thus if you pick the location{" "}
          <i>KitKatClub</i> and the tag <i>Flinta</i>, you will see all events
          happening at Kitkat <b>OR</b> the events maked with the tag flinta.
        </>
      ),
      target: () => ref2.current,
    },
    {
      title: "All events matching your filter",
      description: (
        <>
          All events matching your filter (if any) will be displayed here. If
          you do not see anything, you may want to reset some filter and maybe
          increase the time frame.
        </>
      ),
      target: () => ref3.current,
    },
  ];
};
