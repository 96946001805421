import { action, makeObservable, observable } from "mobx";
import { getPartnerByUserName } from "./getPartnerByUserName.js";
import { getProfilePartnerPhotos } from "./getProfilePartnerPhotos.js";
import { getProfilePartnerEvents } from "./getProfilePartnerEvents.js";
import { getProfilePartnerFollowers } from "./getProfilePartnerFollowers.js";

export class PartnerStore {
  isLoading = true;
  error = null;
  id = null;
  avatar = null;
  avatarUrl = null;
  userName = null;
  name = null;
  description = null;
  pictures = [];
  settings = [];
  reviews = [];
  lastActive = null;
  partnerRoles = [];
  partnerClass = null;
  partnertypeId = null;
  links = [];
  tags = [];
  archived = null;
  suspended = null;
  pending = null;
  admin = [];
  photos = [];
  events = [];
  followers = [];

  constructor() {
    makeObservable(this, {
      isLoading: observable,
      setIsLoading: action,
      error: observable,
      setError: action,
      id: observable,
      setid: action,
      avatarUrl: observable,
      setAvatarUrl: action,
      userName: observable,
      setUserName: action,
      name: observable,
      setName: action,
      description: observable,
      setDescription: action,
      pictures: observable,
      setPictures: action,
      settings: observable,
      setSettings: action,
      reviews: observable,
      setReviews: action,
      lastActive: observable,
      setLastActive: action,
      partnerRoles: observable,
      setPartnerRoles: action,
      partnerClass: observable,
      setPartnerClass: action,
      partnertypeId: observable,
      setPartnertypeId: action,
      links: observable,
      setLinks: action,
      tags: observable,
      setTags: action,
      archived: observable,
      setArchived: action,
      suspended: observable,
      setSuspended: action,
      pending: observable,
      setPending: action,
      admin: observable,
      setAdmin: action,
      events: observable,
      setEvents: action,
      photos: observable,
      setPhotos: action,
      followers: observable,
      setFollowers: action,
      fetchPartnerData: action,
      fetchPartnerPhotos: action,
    });
  }

  setIsLoading = (isLoading) => {
    this.isLoading = isLoading;
  };

  setError = (error) => {
    this.error = error;
  };

  setid = (id) => {
    this.id = id;
  };

  setAvatar = (avatar) => {
    this.avatar = avatar;
  };

  setAvatarUrl = (avatarUrl) => {
    this.avatarUrl = avatarUrl;
  };

  setUserName = (userName) => {
    this.userName = userName;
  };

  setName = (name) => {
    this.name = name;
  };

  setDescription = (description) => {
    this.description = description;
  };

  setPictures = (pictures) => {
    this.pictures = pictures;
  };

  setSettings = (settings) => {
    this.settings = settings;
  };

  setReviews = (reviews) => {
    this.reviews = reviews;
  };

  setLastActive = (lastActive) => {
    this.lastActive = lastActive;
  };

  setPartnerRoles = (partnerRoles) => {
    this.partnerRoles = partnerRoles;
  };

  setPartnerClass = (partnerClass) => {
    this.partnerClass = partnerClass;
  };

  setPartnertypeId = (partnertypeId) => {
    this.partnertypeId = partnertypeId;
  };

  setLinks = (links) => {
    this.links = links;
  };

  setTags = (tags) => {
    this.tags = tags;
  };

  setArchived = (archived) => {
    this.archived = archived;
  };

  setSuspended = (suspended) => {
    this.suspended = suspended;
  };

  setPending = (pending) => {
    this.pending = pending;
  };

  setAdmin = (admin) => {
    this.admin = admin;
  };

  setEvents = (events) => {
    this.events = events;
  };

  setPhotos = (photos) => {
    this.photos = photos;
  };

  setFollowers = (followers) => {
    this.followers = followers;
  };

  fetchPartnerData = async (userName, loader = true) => {
    try {
      if (loader) {
        this.setIsLoading(true);
      }
      if (userName) {
        this.setUserName(userName);
        const partnerData = await getPartnerByUserName(userName);
        if (partnerData) {
          // console.log(partnerData);
          this.setid(parseInt(partnerData.id));
          this.setAvatar(partnerData.avatar);
          this.setName(partnerData.name);
          this.setDescription(partnerData.description);
          this.setLastActive(partnerData.lastActive);
          this.setSettings(JSON.parse(partnerData.settings));
          this.setPictures(partnerData.pictures);
          this.setReviews(partnerData.reviews);
          this.setLinks(partnerData.links);
          this.setPending(partnerData.pending);
          this.setPartnertypeId(parseInt(partnerData.partnertype));
          this.setAdmin(partnerData.admin);
          this.setSuspended(partnerData.suspended);
          this.setTags(partnerData.partnerTags);
          const [events, photos, followers] = await Promise.all([
            getProfilePartnerEvents(partnerData.id),
            getProfilePartnerPhotos(partnerData.id),
            getProfilePartnerFollowers(partnerData.id),
          ]);
          events && this.setEvents(events);
          photos && this.setPhotos(photos);
          followers && this.setFollowers(followers);
        }
        this.setError(null);
      }
    } catch (error) {
      this.setError(error);
      console.error(error);
    }
    this.setIsLoading(false);
  };

  fetchPartnerPhotos = async () => {
    const photos = await getProfilePartnerPhotos(this.id);
    this.setPhotos(photos);
  };
}

export const partnerStore = new PartnerStore();
