import { action, makeObservable, observable } from "mobx";
import Cookies from "universal-cookie";

import { getAllEvents } from "./getAllEvents.js";
import { getAllEventtypes } from "../spielplanStore/getAllEventtypes.js";
import { getUserNames } from "./getUserNames.js";

const cookies = new Cookies();

export class AdminStore {
  eventsLoading = true;
  selectedPage = cookies.get("selectedPage") || null;
  events = [];
  eventtypes = null;
  userNames = null;

  constructor() {
    makeObservable(this, {
      selectedPage: observable,
      setSelectedPage: action,
      events: observable,
      setEvents: action,
      eventtypes: observable,
      setEventtypes: action,
      userNames: observable,
      setUserNames: action,
      eventsLoading: observable,
      setEventsLoading: action,
    });
  }

  setSelectedPage = (selectedPage) => {
    this.selectedPage = selectedPage;
    cookies.set("selectedPage", selectedPage, { path: "/" });
  };

  fetchEvents = async () => {
    this.setEventsLoading(true);
    const results = await getAllEvents();
    const events = results.map((event) => {
      return {
        isPrivate: event.private,
        ...event,
      };
    });
    this.setEvents(events);
    this.setEventsLoading(false);
  };

  setEvents = (events) => {
    this.events = events;
  };

  setEventsLoading = (eventsLoading) => {
    this.eventsLoading = eventsLoading;
  };

  fetchEventtypes = async () => {
    const eventtypes = await getAllEventtypes();
    this.setEventtypes(eventtypes);
  };

  setEventtypes = (eventtypes) => {
    this.eventtypes = eventtypes;
  };

  fetchUserNames = async () => {
    const userNames = await getUserNames();
    this.setUserNames(userNames);
  };

  setUserNames = (userNames) => {
    this.userNames = userNames;
  };
}

export const adminStore = new AdminStore();
