export async function addBlogArticle(dataObject) {
  const graphqlQuery = {
    query: `mutation ( 
                $dataObject: BlogArticleInputData!,
                ) {
                addBlogArticle (
                  blogArticleInput: $dataObject,
                ) {
                  outlet,
                  id,
                  title,
                  body,
                  pictures, 
                  externalPicture,
                  isDraft,
                  teaser,
                  tags,
                  createdAt,
                  updatedAt,
                  isTemplate,
                  userCollaborator,
                }
                }`,
    variables: {
      dataObject,
    },
  };

  const headers = {
    "content-type": "application/json",
  };

  const endpoint = process.env.API_URL + "/graphql";

  const options = {
    method: "POST",
    headers,
    body: JSON.stringify(graphqlQuery),
  };

  const response = await fetch(endpoint, options);
  const responseJson = await response.json();

  if (responseJson.errors) {
    return responseJson.errors[0];
  }
  return responseJson.data.addBlogArticle;
}
