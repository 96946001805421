import React, { useState, useEffect } from "react";
import { Form, Table, Tooltip } from "antd";

import { AdminCustomSpinner } from "../../AdminCustomSpinner/AdminCustomSpinner";
import { getAllBlogArticlesAsAdmin } from "./getAllBlogArticlesAsAdmin";
import { updateBlogArticleAsAdmin } from "./updateBlogArticleAsAdmin";

export const AdminBlogContent = ({ validated, review, draft, template }) => {
  const [form] = Form.useForm();
  const [blogArticles, setBlogArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchBlogArticles = async () => {
    const results = await getAllBlogArticlesAsAdmin();
    if (results) {
      if (validated) {
        setBlogArticles(results.filter((article) => article.validated));
      } else if (review) {
        setBlogArticles(
          results.filter(
            (article) => article.isDraft === false && !article.isTemplate,
          ),
        );
      } else if (draft) {
        setBlogArticles(results.filter((article) => article.isDraft));
      } else if (template) {
        setBlogArticles(results.filter((article) => article.isTemplate));
      } else {
        setBlogArticles(results);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchBlogArticles();
  }, []);

  const toggleIsTemplate = async (id, value) => {
    await updateBlogArticleAsAdmin(id, { isTemplate: value });
    fetchBlogArticles();
  };

  const columns = [
    {
      title: "Created by",
      dataIndex: "user",
      key: "user",
      render: (_, { user }) => {
        return user.userName;
      },
    },
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Teaser",
      dataIndex: "teaser",
      key: "teaser",
    },
    {
      title: "Article",
      dataIndex: "body",
      key: "body",
    },
    {
      title: "isTemplate",
      dataIndex: "isTemplate",
      key: "isTemplate",
      align: "center",
      render: (_, { id, isTemplate }) => {
        return (
          <Tooltip title="Double click to toggle this value">
            <div
              style={{ cursor: "pointer" }}
              onDoubleClick={() => toggleIsTemplate(id, !isTemplate)}
            >
              {isTemplate ? (
                "✅"
              ) : (
                <span style={{ filter: "grayscale(1)", opacity: 0.3 }}>✅</span>
              )}
            </div>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <div>
      {isLoading ? (
        <div className="admin__centered">
          <AdminCustomSpinner text="Loading Blog articles data" />
        </div>
      ) : (
        <Form form={form} component={false}>
          <Table
            className="admin__table"
            dataSource={blogArticles}
            columns={columns}
            pagination={false}
            size="small"
            scroll={{
              x: 1400,
            }}
          />
        </Form>
      )}
    </div>
  );
};
