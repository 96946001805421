import React, { useEffect, useState } from "react";
import { notification } from "antd";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { authStore } from "../../store/authStore/authStore";
import { pageStore } from "../../store/pageStore/pageStore";

import "./CreateAccountPopUp.less";

const TIME_IN_SEC_BEFORE_SHOWING_NOTIFICATON = 60;

export const CreateAccoutPopUp = observer(() => {
  const [hasRenderedOnce, setHasRenderedOnce] = useState(false);
  const identificationKey = "popUpNotificationKey";
  const navigate = useNavigate();

  const redirectToSignUp = () => {
    pageStore.setShowLogin(false);
    navigate("/login");
  };

  const openCreateAccountPlease = () => {
    notification.open({
      key: identificationKey,
      message: <CreateAccountTitle />,
      description: <CreateAccountDesc redirectToSignUp={redirectToSignUp} />,
      duration: 0,
      placement: "bottomRight",
      className: "customNotification",
    });
  };

  useEffect(() => {
    const timerShowPopUp = setTimeout(() => {
      if (hasRenderedOnce === false) {
        if (!authStore.hasAccess) {
          openCreateAccountPlease();
          setHasRenderedOnce(true);
        }
      }
    }, TIME_IN_SEC_BEFORE_SHOWING_NOTIFICATON * 1000);

    return clearTimeout(timerShowPopUp);
  }, []);

  useEffect(() => {
    if (authStore.hasAccess) {
      notification.destroy(identificationKey);
    }
  }, [authStore.hasAccess]);
});

const CreateAccountTitle = () => {
  const { t } = useTranslation();
  return <>👫 {t("general.createAccountPleaseTitle")}</>;
};

const CreateAccountDesc = (props) => {
  const { t } = useTranslation();
  return (
    <div className="createAccount__descLink" onClick={props.redirectToSignUp}>
      {t("general.createAccountPleaseDesc")}
    </div>
  );
};
