import React from "react";
import { useNavigate } from "react-router-dom";

import { blogArticleStore } from "../blogArticleStore";

import "./ArticleDraftSelect.less";

export const ArticleDraftSelect = (props) => {
  const { article, isTemplate } = props;
  const navigate = useNavigate();

  const dateUpdatedAt = new Date(parseInt(article.updatedAt));

  const draftHandlerClick = () => {
    if (isTemplate) {
      blogArticleStore.setArticleSelected(null);
      blogArticleStore.setTemplateSelected(true);
      blogArticleStore.setArticleTitle(article.title);
      blogArticleStore.setArticleTeaser(article.teaser);
      blogArticleStore.setArticleHtmlBody(article.body);
      blogArticleStore.setArticleSelectedTags(article.tags);
      blogArticleStore.setArticleChanged(false);
      navigate("/editblogarticle/", { replace: true });
    } else {
      blogArticleStore.setArticleSelected(article);
      blogArticleStore.setTemplateSelected(false);
      blogArticleStore.setArticleTitle(article.title);
      blogArticleStore.setArticleTeaser(article.teaser);
      blogArticleStore.setArticleHtmlBody(article.body);
      blogArticleStore.setArticleSelectedTags(article.tags);
      blogArticleStore.setArticleChanged(false);
      navigate(`/editblogarticle/${article.id}`, { replace: true });
    }
  };

  return (
    <div
      key={`article${article.id}`}
      onClick={draftHandlerClick}
      className="articleDraft__container"
    >
      {isTemplate ? (
        <div className="articleDraft__date">
          {article.teaser || "Unclassified"}
        </div>
      ) : (
        <div className="articleDraft__date">
          {dateUpdatedAt.toLocaleDateString(undefined, {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })}
          ,&nbsp;
          {dateUpdatedAt.toLocaleTimeString()}
        </div>
      )}
      <div className="articleDraft__title">{article.title || "Untitled"}</div>
    </div>
  );
};
