import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Form,
  Table,
  Typography,
  Popconfirm,
  Tag,
  Button,
  Tooltip,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import dayjs from "dayjs";

import { AdminCustomSpinner } from "../AdminCustomSpinner/AdminCustomSpinner";
import { deleteEvent } from "./deleteEvent";
import { getAllPastEvents } from "./getAllPastEvents";
import { validateEvent } from "./validateEvent";
import { nameParser } from "../../../helpers/dev/nameParser";
import { pageStore } from "../../../store/pageStore/pageStore";
import { pictureOrPlaceholder } from "../../../helpers/picture/pictureOrPlaceholder";
import { adminStore } from "../../../store/adminStore/adminStore";

export const AdminEventsTab = observer((props) => {
  const [eventMediaUrls, setEventMediaUrls] = useState([]);
  const [form] = Form.useForm();
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { tab } = props;

  const navigate = useNavigate();

  const fetchUrlsFromPicturePath = async () => {
    const urls = {};
    await Promise.all(
      // eslint-disable-next-line array-callback-return
      events.map(async (event) => {
        urls[event.id] = await pictureOrPlaceholder(event);
      }),
    );
    setEventMediaUrls(urls);
  };

  const fetchPastsEvents = async () => {
    setIsLoading(true);
    const pastEvents = await getAllPastEvents();
    console.log("pastEvents", pastEvents);
    setEvents(pastEvents);
    setIsLoading(false);
  };

  useEffect(() => {
    if (tab === "all") {
      setEvents(adminStore.events);
    } else if (tab === "pending") {
      const pendingEvent = adminStore.events.filter(
        (event) => event.isDraft === false && event.validated === false,
      );
      setEvents(pendingEvent);
    } else if (tab === "draft") {
      const draftEvent = adminStore.events.filter((event) => event.isDraft);
      setEvents(draftEvent);
    } else if (tab === "validated") {
      const validatedEvent = adminStore.events.filter(
        (event) => event.validated,
      );
      setEvents(validatedEvent);
    } else if (tab === "archived") {
      const archivedEvent = adminStore.events.filter((event) => event.archived);
      setEvents(archivedEvent);
    } else if (tab === "past") {
      fetchPastsEvents();
    }
    setIsLoading(false);
  }, [adminStore.events]);

  useEffect(() => {
    fetchUrlsFromPicturePath();
  }, [events]);

  const deleteRow = async (id) => {
    await deleteEvent(id);
    await adminStore.fetchEvents();
  };

  const toggleValidated = (id, validated) => {
    if (validated) {
      validateEvent(parseInt(id), { validated: false });
    } else {
      validateEvent(parseInt(id), { validated: true, isDraft: false });
    }
    adminStore.fetchEvents();
  };

  const columns = [
    {
      title: "Media",
      dataIndex: "media",
      key: "media",
      align: "center",
      render: (_, { id }) => {
        const handlePartnerContainerClick = () => {
          navigate(`../event/${id}`, { relative: "path" });
        };
        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={handlePartnerContainerClick}
          >
            <img
              src={eventMediaUrls[id]}
              width="100px"
              height="100px"
              style={{ objectFit: "cover" }}
            />
          </div>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "3v 00px",
      render: (_, { description, eventTags, title, id }) => (
        <div>
          <div>
            <div style={{ fontSize: "10px", color: "#999" }}>#{id}</div>
            <div>
              <Link to={`../event/${id}`}>{title}</Link>
            </div>
            <Typography.Text style={{ width: 400 }} ellipsis>
              {description}
            </Typography.Text>
          </div>
          <div className="admmin__tags">
            <Typography.Text style={{ width: 400 }} ellipsis>
              {eventTags.map((tagId) => {
                const tagData = pageStore.tags.find(
                  (tag) => parseInt(tag.id) === tagId,
                );
                const tagName = `${nameParser(tagData?.name, "en")}${!tagData?.validated ? ` (pending review)` : ""}`;
                return (
                  <Tag
                    key={tagId}
                    bordered={false}
                    style={{
                      background:
                        !tagData?.validated && "rgba(178, 34, 34, .75)",
                      color: !tagData?.validated && "white",
                    }}
                  >
                    #{tagName || <i> Loading</i>}
                  </Tag>
                );
              })}
            </Typography.Text>
          </div>
        </div>
      ),
    },
    {
      title: "Date",
      dataIndex: "fromDate",
      key: "fromDate",
      align: "center",
      width: "200px",
      sorter: (a, b) => a.fromDate - b.fromDate,
      render: (_, { fromDate, untilDate }) => {
        if (fromDate === untilDate) {
          return dayjs(fromDate).format("DD-MM-YY HH:mm");
        } else {
          return (
            <>
              <div>{dayjs(fromDate).format("DD-MM-YY")}</div>
              <div>
                {dayjs(fromDate).format("HH:mm") +
                  " " +
                  dayjs(untilDate).format("HH:mm")}
              </div>
            </>
          );
        }
      },
    },
    {
      title: "Type",
      dataIndex: "eventtype",
      key: "eventtype",
      render: (_, { eventtype }) =>
        nameParser(
          adminStore.eventtypes?.filter((e) => parseInt(e.id) === eventtype)[0]
            ?.name,
          pageStore.selectedLanguage.toLowerCase(),
        ),
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      render: (
        _,
        { locationName, location, locationCoordinates, locationAddress },
      ) => {
        // TODO Click to show location page
        return (
          <Tooltip
            placement="bottom"
            title={
              <div>
                <div style={{ fontWeight: 500 }}>{locationAddress}</div>
                <div style={{ opacity: 0.5 }}>{locationCoordinates}</div>
              </div>
            }
          >
            {locationName}
          </Tooltip>
        );
      },
    },
    {
      title: "Admin",
      dataIndex: "admin",
      key: "admin",
      render: (_, { admin }) => (
        <>
          {admin.map((admin) => {
            const adminName = adminStore.userNames?.filter(
              (user) => parseInt(user.id) === admin,
            )[0]?.userName;
            const handleAdminClick = () => {
              navigate(`../user/${adminName}`, { relative: "path" });
            };
            return (
              <Tag
                key={admin}
                bordered={false}
                onClick={handleAdminClick}
                className="admin__tagLink"
              >
                {adminName}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      width: "200px",
      sorter: (a, b) => a.createdAt - b.createdAt,
      render: (_, { createdAt }) => (
        <>
          {dayjs(createdAt).format("DD-MM-YY") +
            " " +
            dayjs(createdAt).format("HH:mm")}
        </>
      ),
    },
    /* {
      title: "Private",
      dataIndex: "isPrivate",
      key: "isPrivate",
      align: "center",
      width: "80px",
      render: (_, { isPrivate }) => isPrivate && "✖️",
    },
    {
      title: "Forwardable",
      dataIndex: "forwardable",
      key: "forwardable",
      align: "center",
      width: "80px",
      render: (_, { forwardable }) => forwardable && "✖️",
    },
    {
      title: "Allow Anonymous",
      dataIndex: "allowAnonymous",
      key: "allowAnonymous",
      align: "center",
      width: "80px",
      render: (_, { allowAnonymous }) => allowAnonymous && "✖️",
    }, */
    {
      title: "Validated",
      dataIndex: "validated",
      key: "validated",
      align: "center",
      render: (_, { validated, isDraft, id, admin }) => {
        if (!isDraft || admin.includes(17)) {
          return (
            <Tooltip title="Double click to toggle this value">
              <div
                style={{ cursor: "pointer" }}
                onDoubleClick={() => toggleValidated(id, validated)}
              >
                {validated ? "✅" : "❌"}
              </div>
            </Tooltip>
          );
        } else {
          return <Tooltip title="Still in draft">⌛</Tooltip>;
        }
      },
    },
    {
      title: " ",
      dataIndex: "action",
      align: "center",
      width: "100px",
      render: (_, record) => {
        return (
          <Popconfirm
            title="Sure to delete?"
            style={{ marginRight: 8 }}
            onConfirm={() => deleteRow(record.id)}
          >
            <DeleteOutlined className="admin__editLogo" />
          </Popconfirm>
        );
      },
    },
  ];

  return (
    <div>
      {isLoading || !adminStore.events?.length ? (
        <div className="admin__centered">
          <AdminCustomSpinner text="Loading Events" />
        </div>
      ) : (
        <>
          <Form form={form} component={false}>
            <Table
              className="admin__table"
              dataSource={events}
              columns={columns}
              pagination={false}
              size="small"
              scroll={{
                x: 1000,
              }}
            />
          </Form>
          <div className="admin__tableFooter">
            <Button>
              <Link to={`../event/add`}>Add a new Event</Link>
            </Button>
          </div>
          <br />
        </>
      )}
    </div>
  );
});
