import React, { useState, useEffect } from "react";
import { Table, Popconfirm, Tooltip, Select } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import { EditableCell } from "../EditableCell";
import { deleteBug } from "./deleteBug";
import { updateBug } from "./updateBug";
import { getPictureUrl } from "../../../helpers/picture/getPictureUrl";
import { pageStore } from "../../../store/pageStore/pageStore";

export const AdminBugsTab = (props) => {
  const [bugsUrls, setBugsUrls] = useState([]);
  const { bugs, fetchBugs } = props;

  const getUrlsFromPicturePath = async (bugs) => {
    const urls = await Promise.all(
      bugs.map((bug) => {
        if (bug?.screenshot) {
          return getPictureUrl(`${bug.screenshot}`, "bugs");
        } else {
          return null;
        }
      }),
    );
    setBugsUrls(urls);
  };

  useEffect(() => {
    getUrlsFromPicturePath(bugs);
  }, [bugs]);

  const deleteRow = async (id) => {
    await deleteBug(id);
    await fetchBugs();
  };

  const toogleUrgent = async (id, value) => {
    await updateBug(id, { isUrgent: value });
    await fetchBugs();
  };

  const toogleResolved = async (id, value) => {
    await updateBug(id, { isResolved: value });
    await fetchBugs();
  };

  const handleCategoryChange = async (id, value) => {
    await updateBug(id, { category: value });
    await fetchBugs();
  };

  const bugCategories = [
    { value: 0, label: "default" },
    { value: 1, label: "Spielplan" },
    { value: 2, label: "Event" },
    { value: 3, label: "Shop" },
    { value: 5, label: "Homepage" },
    { value: 6, label: "Profile" },
  ];

  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: "50px",
    },
    {
      title: "Cat",
      dataIndex: "category",
      key: "category",
      width: "120px",
      editable: true,
      render: (_, { id, category }) => {
        return (
          <Select
            value={category}
            options={bugCategories}
            onChange={(value) => handleCategoryChange(id, value)}
          />
        );
      },
    },
    {
      title: "Desc",
      dataIndex: "desc",
      key: "desc",
      editable: true,
    },
    {
      title: "Screenshot",
      dataIndex: "url",
      key: "url",
      editable: false,
      width: "100px",
      render: (_, __, index) => {
        return (
          <div
            onClick={() => {
              if (bugsUrls[index]) {
                pageStore.setPicturesUrls([{ url: bugsUrls[index] }]);
                pageStore.setPictureSelected(0);
                pageStore.setShowOverlayGallery(true);
              }
            }}
            className={bugsUrls[index] && "screenshotCell"}
          >
            <img src={bugsUrls[index]} width={100} height={70} />
          </div>
        );
      },
    },
    {
      title: "Urgent",
      dataIndex: "isUrgent",
      key: "isUrgent",
      align: "center",
      editable: true,
      width: "100px",
      render: (_, { id, isUrgent, isResolved }) => {
        return (
          <Tooltip title="Double click to toggle this value">
            <div
              style={{ cursor: "pointer" }}
              onDoubleClick={() => toogleUrgent(id, !isUrgent)}
            >
              {isUrgent && !isResolved ? (
                "🔥"
              ) : (
                <span style={{ filter: "grayscale(1)", opacity: 0.3 }}>🔥</span>
              )}
            </div>
          </Tooltip>
        );
      },
      filters: [
        { text: "Urgent", value: true },
        { text: "Not urgent", value: false },
      ],
      onFilter: (value, record) => record.isUrgent === value,
    },
    {
      title: "Resolved",
      dataIndex: "isResolved",
      key: "isResolved",
      align: "center",
      editable: true,
      width: "100px",
      render: (_, { id, isResolved }) => {
        return (
          <Tooltip title="Double click to toggle this value">
            <div
              style={{ cursor: "pointer" }}
              onDoubleClick={() => toogleResolved(id, !isResolved)}
            >
              {isResolved ? (
                "✅"
              ) : (
                <span style={{ filter: "grayscale(1)", opacity: 0.3 }}>✅</span>
              )}
            </div>
          </Tooltip>
        );
      },
      filters: [
        { text: "Resolved", value: true },
        { text: "Not resolved", value: false },
      ],
      onFilter: (value, record) => record.isResolved === value,
    },
    {
      title: <span style={{ opacity: ".2" }}> </span>,
      dataIndex: "edit",
      width: "90px",
      align: "center",
      render: (_, record) => {
        return (
          <Popconfirm
            title="Sure to delete?"
            style={{ marginRight: 8 }}
            onConfirm={() => deleteRow(record.id)}
          >
            <DeleteOutlined className="admin__editLogo" />
          </Popconfirm>
        );
      },
    },
  ];

  /* const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType:
          col.dataIndex === "category"
            ? "select"
            : col.dataIndex === "isUrgent" || col.dataIndex === "isResolved"
              ? "boolean"
              : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        options: Object.keys(categoryBugMapping).map((key) => {
          return { value: key, label: categoryBugMapping[key] };
        }),
      }),
    };
  }); */

  return (
    <div>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        className="admin__table"
        dataSource={bugs}
        columns={columns}
        pagination={false}
        size="small"
      />
    </div>
  );
};
