import { action, makeObservable, observable } from "mobx";
import { getTags } from "../../../store/pageStore/getTags.js";
import { getBlogArticles } from "./getBlogArticles.js";

export class BlogArticleStore {
  isLoading = true;
  blogArticles = [];
  articleTags = [];
  articleHtmlBody = "";
  articleTeaser = "";
  articleTitle = "";
  articleSelectedTags = [];
  articleChanged = false;
  articleSelected = null;
  templateSelected = false;

  constructor() {
    makeObservable(this, {
      articleTags: observable,
      setArticleTags: action,
      isLoading: observable,
      setIsLoading: action,
      fetchData: action,
      articleHtmlBody: observable,
      setArticleHtmlBody: action,
      articleTeaser: observable,
      setArticleTeaser: action,
      articleTitle: observable,
      setArticleTitle: action,
      articleSelectedTags: observable,
      setArticleSelectedTags: action,
      blogArticles: observable,
      setBlogArticles: action,
      articleChanged: observable,
      setArticleChanged: action,
      articleSelected: observable,
      setArticleSelected: action,
      templateSelected: observable,
      setTemplateSelected: action,
    });
  }

  setTemplateSelected = (templateSelected) => {
    this.templateSelected = templateSelected;
  };

  setIsLoading = (isLoading) => {
    this.isLoading = isLoading;
  };

  setArticleChanged = (articleChanged) => {
    this.articleChanged = articleChanged;
  };

  setArticleSelected = (articleSelected) => {
    this.articleSelected = articleSelected;
  };

  setArticleTags = (articleTags) => {
    this.articleTags = articleTags;
  };

  setBlogArticles = (blogArticles) => {
    this.blogArticles = blogArticles;
  };

  fetchData = async () => {
    this.setIsLoading(true);
    try {
      const dataTags = await getTags();
      this.setArticleTags(dataTags.filter((tag) => tag.isBlogArticleTag));
      const dataBlogArticles = await getBlogArticles();
      this.setBlogArticles(dataBlogArticles);
      this.setIsLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  setArticleHtmlBody = (articleHtmlBody) => {
    this.articleHtmlBody = articleHtmlBody;
  };

  setArticleTeaser = (articleTeaser) => {
    this.articleTeaser = articleTeaser;
  };

  setArticleTitle = (articleTitle) => {
    this.articleTitle = articleTitle;
  };

  setArticleSelectedTags = (articleSelectedTags) => {
    this.articleSelectedTags = articleSelectedTags;
  };
}

export const blogArticleStore = new BlogArticleStore();
