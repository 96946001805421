import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Tabs } from "antd";
import {
  CalendarOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import { AdminEventsTab } from "./AdminEventsTab";
import { adminStore } from "../../../store/adminStore/adminStore";

export const AdminEvents = observer(() => {
  useEffect(() => {
    adminStore.fetchEvents();
    adminStore.fetchEventtypes();
    adminStore.fetchUserNames();
  }, []);

  const tabItems = [
    {
      key: "1",
      label: (
        <>
          <CalendarOutlined /> All Upcoming Events
        </>
      ),
      children: <AdminEventsTab tab={"all"} />,
    },
    {
      key: "2",
      label: (
        <>
          <CheckCircleOutlined /> Pending Events
        </>
      ),
      children: <AdminEventsTab tab={"pending"} />,
    },
    {
      key: "3",
      label: (
        <>
          <ClockCircleOutlined /> Draft Events
        </>
      ),
      children: <AdminEventsTab tab={"draft"} />,
    },
    {
      key: "4",
      label: (
        <>
          <ExclamationCircleOutlined /> Past Events
        </>
      ),
      children: <AdminEventsTab tab={"past"} />,
    },
    {
      key: "5",
      label: (
        <>
          <DeleteOutlined /> Archived Events
        </>
      ),
      children: <AdminEventsTab tab={"archived"} />,
    },
  ];

  return (
    <Tabs defaultActiveKey={"2"} items={tabItems} className="admin__tabs" />
  );
});
