import React from "react";
import { Tabs } from "antd";
import {
  ReadOutlined,
  LinkOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  AlignCenterOutlined,
} from "@ant-design/icons";
import { AdminLinktree } from "./AdminLinktree/AdminLinktree";
import { AdminBlogContent } from "./AdminBlogContent/AdminBlogContent";

export const AdminContent = () => {
  const items = [
    {
      key: "1",
      label: (
        <>
          <ReadOutlined /> Published articles
        </>
      ),
      children: <AdminBlogContent validated={true} />,
    },
    {
      key: "2",
      label: (
        <>
          <CheckCircleOutlined /> Articles pending review
        </>
      ),
      children: <AdminBlogContent review={true} />,
    },
    {
      key: "3",
      label: (
        <>
          <ClockCircleOutlined /> Draft articles
        </>
      ),
      children: <AdminBlogContent draft={true} />,
    },
    {
      key: "4",
      label: (
        <>
          <AlignCenterOutlined /> Templates
        </>
      ),
      children: <AdminBlogContent template={true} />,
    },
    {
      key: "5",
      label: (
        <>
          <LinkOutlined /> Linktree
        </>
      ),
      children: <AdminLinktree />,
    },
  ];

  return (
    <div>
      <Tabs defaultActiveKey="1" items={items} className="admin__tabs" />
    </div>
  );
};
