import React, { useState, useEffect, useRef } from "react";
import {
  LeftOutlined,
  RightOutlined,
  CloseOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { observer } from "mobx-react";

import { pageStore } from "../../store/pageStore/pageStore";
import { userStore } from "../../store/userStore/userStore";

import "./GalleryOverlay.less";

// TODO: Like feature
// TODO: Comment feature
// TODO: Edit desc feature

// the required distance between touchStart and touchEnd to be detected as a swipe
const MIN_SWIPE_DISTANCE = 100;

export const GalleryOverlay = observer(() => {
  const [indexImageSelected, setIndexImageSelected] = useState(
    pageStore.pictureSelected,
  );
  const [imageLoaded, setImageLoaded] = useState(null);
  const [isPictureLoading, setIsPictureLoading] = useState(false);
  const [windowInnerHeight, setWindowInnerHeight] = useState(
    window.innerHeight,
  );
  const touchStart = useRef(null);
  const touchEnd = useRef(null);
  const throttling = useRef(false);

  const onTouchStart = (e) => {
    touchEnd.current = null; // otherwise the swipe is fired even with usual touch events
    touchStart.current = e.targetTouches[0].clientX;
  };

  const onTouchMove = (e) => {
    touchEnd.current = e.targetTouches[0].clientX;
  };

  const onTouchEnd = () => {
    if (!touchStart.current || !touchEnd.current) return;
    const distance = touchStart.current - touchEnd.current;
    const isLeftSwipe = distance > MIN_SWIPE_DISTANCE;
    const isRightSwipe = distance < -MIN_SWIPE_DISTANCE;
    if (throttling.current === false) {
      throttling.current = true;
      if (isRightSwipe) {
        browsePicture(false);
      } else if (isLeftSwipe) {
        browsePicture(true);
      }
      setTimeout(() => {
        throttling.current = false;
      }, 500);
    }
  };

  const browsePicture = (direction) => {
    if (direction) {
      if (indexImageSelected + 1 !== pageStore.picturesUrls.length) {
        setIndexImageSelected(indexImageSelected + 1);
      }
    } else {
      if (indexImageSelected - 1 >= 0) {
        setIndexImageSelected(indexImageSelected - 1);
      }
    }
    // reset close button
    const closeButton = document.getElementById("closeButton");
    closeButton.style.visibility = "visible";
    closeButton.style.opacity = 1;
  };

  const loadImage = async () => {
    try {
      setImageLoaded(null);
      const isloaded = new Promise((resolve, reject) => {
        const loadImg = new Image();
        loadImg.src = pageStore.picturesUrls[indexImageSelected].url;
        loadImg.onload = () =>
          resolve(pageStore.picturesUrls[indexImageSelected].url);
      });
      await isloaded;
      setImageLoaded(pageStore.picturesUrls[indexImageSelected].url);
      setIsPictureLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    loadImage();
  }, [indexImageSelected]);

  const mouseHoverHandler = (hover) => {
    const closeButton = document.getElementById("closeButton");
    if (hover) {
      closeButton.style.visibility = "hidden";
      closeButton.style.opacity = 0;
    } else {
      closeButton.style.visibility = "visible";
      closeButton.style.opacity = 1;
    }
  };

  const keyDownHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const keyPressed = event.key.toLowerCase();
    const nextButton = document.getElementById("nextButton");
    const previousButton = document.getElementById("previousButton");
    if (throttling.current === false) {
      throttling.current = true;
      if (keyPressed === "arrowdown" || keyPressed === "arrowright") {
        if (nextButton) {
          nextButton.style.backgroundColor = "rgba(255,255,255,.15)";
        }
        browsePicture(true);
        setTimeout(() => {
          if (nextButton) {
            nextButton.style.backgroundColor = "rgba(255,255,255, 0)";
          }
        }, 100);
      } else if (keyPressed === "arrowup" || keyPressed === "arrowleft") {
        if (previousButton) {
          previousButton.style.backgroundColor = "rgba(255,255,255,.15)";
        }
        browsePicture(false);
        setTimeout(() => {
          if (previousButton) {
            previousButton.style.backgroundColor = "rgba(255,255,255, 0)";
          }
        }, 100);
      } else if (keyPressed === "escape") {
        pageStore.setShowOverlayGallery(false);
      }
      setTimeout(() => {
        throttling.current = false;
      }, 100);
    }
  };

  const resetWindowInnerHeight = () => {
    setWindowInnerHeight(window.innerHeight);
  };

  useEffect(() => {
    document.addEventListener("keydown", keyDownHandler);
    window.addEventListener("resize", resetWindowInnerHeight);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
      window.removeEventListener("resize", resetWindowInnerHeight);
    };
  }, [keyDownHandler, resetWindowInnerHeight]);

  return (
    <div
      className="overlay__overlay"
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      style={{ height: windowInnerHeight }}
    >
      <div
        className="overlay__background"
        style={{ height: windowInnerHeight }}
        onClick={() => {
          pageStore.setShowOverlayGallery(false);
        }}
      ></div>
      {indexImageSelected !== 0 && (
        <div
          className="overlay__columnLeft"
          style={{ height: windowInnerHeight }}
          id="previousButton"
          onClick={() => {
            browsePicture(false);
          }}
        >
          <LeftOutlined />
        </div>
      )}
      {indexImageSelected !== pageStore.picturesUrls.length - 1 && (
        <div
          className="overlay__columnRight"
          style={{ height: windowInnerHeight }}
          id="nextButton"
          onMouseEnter={() => mouseHoverHandler(true)}
          onMouseLeave={() => mouseHoverHandler(false)}
          onClick={() => {
            browsePicture(true);
          }}
        >
          <RightOutlined />
        </div>
      )}
      <div
        className="overlay__closeButton"
        id="closeButton"
        onClick={() => {
          pageStore.setShowOverlayGallery(false);
        }}
      >
        <CloseOutlined />
      </div>
      <div className="overlay__pictureContainer">
        {isPictureLoading || imageLoaded === null ? (
          <div className="overlay__pictureLoading">
            <LoadingOutlined className="overlay__spinner" />
          </div>
        ) : (
          <>
            <div className="overlay__infoSpacerTop">
              {userStore.isAdmin && (
                <div className="overlay__id">
                  {pageStore.picturesUrls[indexImageSelected].id &&
                    `#${pageStore.picturesUrls[indexImageSelected].id}`}
                </div>
              )}
            </div>
            <img
              className="overlay__picture"
              src={imageLoaded}
              key={`img__${indexImageSelected}`}
            />
            <div className="overlay__infoSpacerBottom">
              <div className="overlay__text">
                {pageStore.picturesUrls[indexImageSelected].description}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
});
