import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { AutoComplete, Modal, Button, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { DeleteOutlined } from "@ant-design/icons";
import isEqual from "lodash.isequal";

import { pageStore } from "../../../../../store/pageStore/pageStore";
import { UserAvatar } from "../../../../../components/UserAvatar/UserAvatar";
import { updateBlogArticle } from "../updateBlogArticle";
import { blogArticleStore } from "../../blogArticleStore";
import { searchUser } from "./searchUser";

import "./SelectCollaboratorModal.less";

export const SelectCollaboratorModal = observer((props) => {
  const { t } = useTranslation();
  const { showModal, setShowModal, title, selectedUser, setSelectedUser } =
    props;
  const [hasNotChanged, setHasNotChanged] = useState(true);
  const [userOptions, setUserOptions] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [userSelectValue, setUserSelectValue] = useState(null);
  const [showForgotSaveTooltip, setShowForgotSaveTooltip] = useState(false);
  const [selectedCollaboratorsTemp, setSelectedCollaboratorsTemp] =
    useState(selectedUser);

  const userTypeText = {
    isAdmin: t("collabform.userTypeTextIsAdmin"),
    isBlogWritter: t("collabform.userTypeTextIsBlogWritter"),
    isUser: t("collabform.userTypeTextIsUser"),
  };

  const userSearchHandler = async (searchString) => {
    setUserSelectValue(searchString);
    const resultUsersList = await searchUser(searchString);
    setUsersList(resultUsersList);
    const userListUserNames = resultUsersList.map((user) => {
      return { label: user.userName, value: user.id };
    });
    setUserOptions(userListUserNames);
  };

  const userSelectHandler = (id) => {
    const userAlreadyCollaborator = selectedCollaboratorsTemp.filter(
      (user) => user.id === id,
    );
    if (userAlreadyCollaborator.length === 0) {
      const userSelected = usersList.filter((user) => user.id === id);
      setSelectedCollaboratorsTemp([
        ...selectedCollaboratorsTemp,
        userSelected[0],
      ]);
    }
    setUserSelectValue(null);
    setUserOptions([]);
  };

  const handleDeleteCollaborator = (id) => {
    const filteredCollaboratorArray = selectedCollaboratorsTemp.filter(
      (user) => user.id !== id,
    );
    setSelectedCollaboratorsTemp(filteredCollaboratorArray);
  };

  const handleCloseModal = () => {
    if (!hasNotChanged && showForgotSaveTooltip === false) {
      setShowForgotSaveTooltip(true);
    } else {
      setShowForgotSaveTooltip(false);
      setUserSelectValue(null);
      setUserOptions([]);
      setSelectedCollaboratorsTemp(selectedUser);
      setTimeout(() => {
        setShowModal(false);
      }, 500);
    }
  };

  const saveCollaboratorsHandler = async () => {
    setSelectedUser(selectedCollaboratorsTemp);
    const userCollaboratorIds = selectedCollaboratorsTemp.map((user) =>
      parseInt(user.id),
    );
    updateBlogArticle(blogArticleStore.articleSelected?.id, {
      userCollaborator: userCollaboratorIds,
    });
    blogArticleStore.fetchData();
    setUserSelectValue(null);
    setUserOptions([]);
    setShowModal(false);
  };

  useEffect(() => {
    if (isEqual(selectedUser, selectedCollaboratorsTemp)) {
      setHasNotChanged(true);
    } else {
      setHasNotChanged(false);
    }
  }, [selectedCollaboratorsTemp]);

  useEffect(() => {
    setSelectedCollaboratorsTemp(selectedUser);
  }, [selectedUser]);

  return (
    <Modal
      title={<div className="selectCollaboratorModal__title">{title}</div>}
      open={showModal}
      onCancel={handleCloseModal}
      centered={true}
      footer={
        <div className="selectCollaboratorModal__footerContainer">
          <Tooltip
            open={showForgotSaveTooltip}
            title={t("general.forgetToSave")}
            placement="bottom"
          >
            <Button
              className={`selectCollaboratorModal__footerButtonCentered ${pageStore.selectedTheme === "light" ? "lightColorTheme__Button" : "darkColorTheme__Button"}`}
              onClick={saveCollaboratorsHandler}
              disabled={hasNotChanged}
            >
              {t("general.save")}
            </Button>
          </Tooltip>
        </div>
      }
      className={`selectCollaboratorModal__modal ${pageStore.selectedTheme === "light" ? "backgroundLight" : "backgroundDark"}`}
    >
      {t("collabform.collaborators")} ({selectedCollaboratorsTemp.length})
      <div className="selectCollaboratorModal__collaboratorsContainer">
        {selectedCollaboratorsTemp.length ? (
          selectedCollaboratorsTemp?.map((user) => {
            return (
              <div
                key={`collaboratorModalUser${user.id}`}
                className={`selectCollaboratorModal__collaboratorContainer 
                ${pageStore.selectedTheme === "light" ? "collaborator__black" : "collaborator__white"}`}
              >
                <UserAvatar userName={user.userName} path={user.avatar} />
                <div className={"selectCollaboratorModal__collaboratorName"}>
                  <div style={{ fontWeight: 500 }}>@{user.userName}</div>
                  <div
                    style={{ opacity: 0.8, letterSpacing: 0, maxWidth: 360 }}
                  >
                    {user.isAdmin
                      ? userTypeText.isAdmin
                      : user.isBlogWritter
                        ? userTypeText.isBlogWritter
                        : userTypeText.isUser}
                  </div>
                </div>
                <div
                  className={"selectCollaboratorModal__collaboratorDelete"}
                  onClick={() => handleDeleteCollaborator(user.id)}
                >
                  <DeleteOutlined />
                </div>
              </div>
            );
          })
        ) : (
          <div className={"selectCollaboratorModal__noCollaboratorYet"}>
            {t("collabform.noCollaboratorsYet")}
          </div>
        )}
      </div>
      {t("collabform.searchandAddCollaborator")}
      <AutoComplete
        placeholder={t("collabform.userSearch")}
        onSearch={userSearchHandler}
        onSelect={userSelectHandler}
        className="selectCollaboratorModal__userSearch"
        options={userOptions}
        value={userSelectValue}
      />
    </Modal>
  );
});
