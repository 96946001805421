export const yesNoOptions = {
  en: [
    {
      value: false,
      label: "No",
    },
    {
      value: true,
      label: "Yes",
    },
  ],
  de: [
    {
      value: false,
      label: "Nein",
    },
    {
      value: true,
      label: "Ja",
    },
  ],
};
