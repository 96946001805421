import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Tabs } from "antd";
import {
  CheckCircleOutlined,
  BugOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import { AdminCustomSpinner } from "../AdminCustomSpinner/AdminCustomSpinner";
import { getBugs } from "./getBugs";
import { AdminBugsTab } from "./AdminBugsTab";

export const AdminBugs = observer(() => {
  const [bugs, setBugs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchBugs = async () => {
    const results = await getBugs();
    setBugs(results);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchBugs();
  }, []);

  const pendingBugs = bugs.filter((bug) => bug.isResolved === false);

  const resolvedBugs = bugs.filter((bug) => bug.isResolved === true);

  const tabItems = [
    {
      key: "1",
      label: (
        <>
          <BugOutlined /> All bugs
        </>
      ),
      children: <AdminBugsTab tab={"all"} bugs={bugs} fetchBugs={fetchBugs} />,
    },
    {
      key: "2",
      label: (
        <>
          <ExclamationCircleOutlined /> Pending
        </>
      ),
      children: (
        <AdminBugsTab
          tab={"pending"}
          bugs={pendingBugs}
          fetchBugs={fetchBugs}
        />
      ),
    },
    {
      key: "3",
      label: (
        <>
          <CheckCircleOutlined /> Resolved
        </>
      ),
      children: (
        <AdminBugsTab
          tab={"resolved"}
          bugs={resolvedBugs}
          fetchBugs={fetchBugs}
        />
      ),
    },
  ];

  return isLoading ? (
    <div className="admin__centered">
      <AdminCustomSpinner text="Loading Bugs" />
    </div>
  ) : (
    <Tabs
      defaultActiveKey={pendingBugs.length ? "2" : "1"}
      items={tabItems}
      className="admin__tabs"
    />
  );
});
