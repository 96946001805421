import React from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ArrowLeftOutlined } from "@ant-design/icons";

import { pageStore } from "../../store/pageStore/pageStore";

import "./AlreadyMember.less";

export const AlreadyMember = observer((props) => {
  const { t } = useTranslation();

  return (
    <div className="alreadyMember__block">
      <div className="alreadyMember__container">
        <div
          className={`login__goBack ${pageStore.selectedTheme === "light" ? "lightColorTheme__SubText" : "darkColorTheme__SubText"}`}
        >
          <Link to="/" relative="path">
            <ArrowLeftOutlined />
          </Link>
        </div>
        <div
          className={`login__alreadyMember ${pageStore.selectedTheme === "light" ? "lightColorTheme__SubText" : "darkColorTheme__SubText"}`}
        >
          <div style={{ paddingTop: "15px" }}>
            {pageStore.showLogin ? (
              <>
                {t("login.newHere")}?{" "}
                <a
                  className="link"
                  onClick={() => pageStore.setShowLogin(!pageStore.showLogin)}
                >
                  {t("login.signUp")}
                </a>
              </>
            ) : (
              <>
                {t("login.member")}?{" "}
                <a
                  className="link"
                  onClick={() => pageStore.setShowLogin(!pageStore.showLogin)}
                >
                  {t("login.login")}
                </a>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
