export const forbiddenUserNames = {
  admin: 1,
  administrator: 1,
  support: 1,
  usersupport: 1,
  partnersupport: 1,
  accounting: 1,
  billing: 1,
  rechnung: 1,
  buchhaltung: 1,
  recoverpwd: 1,
  emailverify: 1,
  info: 1,
  profile: 1,
};
