import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "antd";
import { LoadingOutlined, UserOutlined } from "@ant-design/icons";

import { getPictureUrl } from "../../helpers/picture/getPictureUrl";
import { profileStore } from "../../store/profileStore/profileStore";
import { partnerStore } from "../../store/partnerStore/partnerStore";

import "./UserAvatar.less";

export const UserAvatar = (props) => {
  const [avatarLoading, setAvatarLoading] = useState(true);
  const [avatar, setAvatar] = useState(null);
  const {
    userName,
    path,
    size = "medium",
    clickable = true,
    isPartner = false,
  } = props;

  const fetchAvatarUrls = async () => {
    try {
      if (path) {
        const avatarUrl = await getPictureUrl(
          `${path}_t`,
          isPartner ? "partners" : "users",
        );
        setAvatar(avatarUrl);
        const isloaded = new Promise((resolve, reject) => {
          const loadImg = new Image();
          loadImg.src = avatarUrl;
          loadImg.onload = () => resolve(avatarUrl);
        });
        await isloaded;
      }
    } catch (e) {
      console.error(e);
    }
    setAvatarLoading(false);
  };

  useEffect(() => {
    fetchAvatarUrls();
  }, [path]);

  return (
    <Tooltip title={userName}>
      {avatarLoading ? (
        <div
          className={`userAvatar__avatar ${size === "medium" ? "userAvatar__avatarMedium" : "userAvatar__avatarSmall"}`}
        >
          <div className="userAvatar__loader">
            <LoadingOutlined className="userAvatar__loaderSpinner" spin />
          </div>
        </div>
      ) : clickable ? (
        <Link
          to={isPartner ? `../partner/${userName}` : `../user/${userName}`}
          onClick={() => {
            if (isPartner) {
              partnerStore.fetchPartnerData(userName);
            } else {
              profileStore.fetchProfileData(userName);
            }
          }}
        >
          <div
            className={`userAvatar__avatar userAvatar__avatarCursor ${size === "medium" ? "userAvatar__avatarMedium" : "userAvatar__avatarSmall"}`}
            style={{
              backgroundImage: `url(${avatar})`,
            }}
          >
            {!path && <UserOutlined className="userAvatar__noAvatar" />}
          </div>
        </Link>
      ) : (
        <div
          className={`userAvatar__avatar ${size === "medium" ? "userAvatar__avatarMedium" : "userAvatar__avatarSmall"}`}
          style={{
            backgroundImage: `url(${avatar})`,
          }}
        >
          {!path && <UserOutlined className="userAvatar__noAvatar" />}
        </div>
      )}
    </Tooltip>
  );
};
