import React, { useEffect, useState, useRef } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Tour } from "antd";
import dayjs from "dayjs";

import { CustomSpinner } from "../../../components/CustomSpinner/CustomSpinner";
import { EventCard } from "./EventCard/EventCard";
import { nameParser } from "../../../helpers/dev/nameParser";
import { spielplanStore } from "../../../store/spielplanStore/spielplanStore";
import { pageStore } from "../../../store/pageStore/pageStore";
import { EventFilters } from "./EventFilters/EventFilters";
import { HelpButtons } from "../../../components/HelpButtons/HelpButtons";
import { spielplanTourSteps } from "./spielplanTourSteps";

import "./Spielplan.less";

// the required distance between touchStart and touchEnd to be detected as a swipe
const MIN_SWIPE_DISTANCE = 100;

export const Spielplan = observer(() => {
  const { t } = useTranslation();
  const [startTour, setStartTour] = useState(false);

  const touchStart = useRef(null);
  const touchEnd = useRef(null);
  const throttling = useRef(false);

  const onTouchStart = (e) => {
    touchEnd.current = null; // otherwise the swipe is fired even with usual touch events
    touchStart.current = e.targetTouches[0].clientX;
  };

  const onTouchMove = (e) => {
    touchEnd.current = e.targetTouches[0].clientX;
  };

  const onTouchEnd = () => {
    if (!touchStart.current || !touchEnd.current) return;
    const distance = touchStart.current - touchEnd.current;
    const isLeftSwipe = distance > MIN_SWIPE_DISTANCE;
    const isRightSwipe = distance < -MIN_SWIPE_DISTANCE;
    if (throttling.current === false) {
      throttling.current = true;
      if (isRightSwipe) {
        spielplanStore.calculateFilterDateFrom(false);
      } else if (isLeftSwipe) {
        spielplanStore.calculateFilterDateFrom(true);
      }
      setTimeout(() => {
        throttling.current = false;
      }, 500);
    }
  };

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);

  useEffect(() => {
    spielplanStore.fetchEventtypes();
    spielplanStore.fetchLocations();
    spielplanStore.fetchTags();
    spielplanStore.fetchEvents();
  }, []);

  useEffect(() => {
    if (
      spielplanStore.eventtypes.length > 0 &&
      spielplanStore.locations.length > 0 &&
      spielplanStore.tags.length > 0
    ) {
      spielplanStore.setIsLoadingData(false);
    }
  }, [
    spielplanStore.eventtypes,
    spielplanStore.locations,
    spielplanStore.tags,
  ]);

  const eventsFormatted = spielplanStore.events?.map((event, index) => {
    const eventColor = spielplanStore.eventtypes?.filter(
      (et) => parseInt(et.id) === event.eventtype,
    )[0]?.color;
    const eventTags = event.eventTags.map((tagId) => {
      return {
        name: nameParser(
          spielplanStore.tags.filter((tag) => parseInt(tag.id) === tagId)[0]
            ?.name,
          pageStore.selectedLanguage?.toLowerCase(),
        ),
        id: tagId,
      };
    });
    const eventType = spielplanStore.eventtypes.filter(
      (et) => parseInt(et.id) === event.eventtype,
    )[0];
    eventTags.splice(0, 0, {
      name: nameParser(
        eventType?.name,
        pageStore.selectedLanguage?.toLowerCase(),
      ),
      id: eventType?.id,
    });

    if (spielplanStore.filterLocations.length) {
      if (!spielplanStore.filterLocations.includes(String(event.location))) {
        return null;
      }
    }

    if (spielplanStore.filterEventtypes.length) {
      if (!spielplanStore.filterEventtypes.includes(String(event.eventtype))) {
        return null;
      }
    }

    if (spielplanStore.filterTags.length) {
      if (
        !spielplanStore.filterTags.some((tag) =>
          event.eventTags.includes(parseInt(tag)),
        )
      ) {
        return null;
      }
    }

    return index === 0 ? (
      <div ref={ref3}>
        <EventCard
          key={`eventCard${event.id}`}
          event={event}
          color={eventColor}
          tags={eventTags}
          eventUser={event.user}
        />
      </div>
    ) : (
      <EventCard
        key={`eventCard${event.id}`}
        event={event}
        color={eventColor}
        tags={eventTags}
        eventUser={event.user}
      />
    );
  });

  const eventsFormattedAndCleaned = eventsFormatted?.filter((events) => events);

  return (
    <>
      {spielplanStore.isLoadingData ? (
        <div className="spielplan__spinnerContainer">
          <CustomSpinner text={`${t("general.loading")} (Data)`} />
        </div>
      ) : (
        <div
          className="spielplan__container"
          onTouchStart={onTouchStart}
          onTouchMove={onTouchMove}
          onTouchEnd={() => onTouchEnd()}
          key={`spielplanContainer${dayjs().unix()}`}
        >
          <HelpButtons missingEvent={true} setStartTour={setStartTour} />
          <EventFilters ref1={ref1} ref2={ref2} />
          {spielplanStore.isLoadingEvent ? (
            <div className="spielplan__noEventContainer" ref={ref3}>
              <CustomSpinner text={`${t("general.loading")} (Events)`} />
            </div>
          ) : eventsFormattedAndCleaned.length === 0 ? (
            <div className="spielplan__noEventContainer">
              <div
                className={`spielplan__noEventText ${pageStore.selectedTheme === "light" ? "lightColorTheme__SubText" : "darkColorTheme__SubText"}`}
              >
                <div>{t("spielplan.noEventsFound")}</div>
                <div className="spielplan__noEventSubText">
                  {t("spielplan.tryOtherFilter")}
                </div>
              </div>
            </div>
          ) : (
            eventsFormattedAndCleaned
          )}
        </div>
      )}
      <Tour
        open={startTour}
        onClose={() => setStartTour(false)}
        steps={spielplanTourSteps(ref1, ref2, ref3)}
      />
    </>
  );
});
