export async function addPicture(
  photo,
  description,
  isAdult,
  isPrivate,
  partnerId,
) {
  const graphqlQuery = {
    query: `
            mutation (
                $photo: String!, 
                $description: String, 
                $isAdult: Boolean, 
                $isPrivate: Boolean,
                $partnerId: Int,
              ) {
                addPhoto(
                  photoInput: { 
                    photo: $photo,
                    description: $description,
                    isAdult: $isAdult,
                    isPrivate: $isPrivate,
                    partnerId: $partnerId,
                  }
              ) {
                id
              }
            }
            `,
    variables: {
      photo,
      description,
      isAdult,
      isPrivate,
      partnerId,
    },
  };

  const headers = {
    "content-type": "application/json",
  };

  const endpoint = process.env.API_URL + "/graphql";

  const options = {
    method: "POST",
    headers,
    body: JSON.stringify(graphqlQuery),
  };

  const response = await fetch(endpoint, options);
  const data = await response.json();

  if (data.errors) {
    return data;
  }
  return data.data.addPhoto;
}
