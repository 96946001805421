import React, { useEffect, useState, useRef } from "react";
import { Tour } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import { EventPageArtwork } from "./EventPageArtwork/EventPageArtwork";
import { EventPageDesc } from "./EventPageDesc/EventPageDesc";
import { EventPageInValidation } from "./EventPageInValidation/EventPageInValidation";
import { spielplanStore } from "../../../../store/spielplanStore/spielplanStore";
import { pageStore } from "../../../../store/pageStore/pageStore";
import { userStore } from "../../../../store/userStore/userStore";
import { getSingleEvents } from "../getSingleEvents";
import { HelpButtons } from "../../../../components/HelpButtons/HelpButtons";
import { CustomSpinner } from "../../../../components/CustomSpinner/CustomSpinner";
import { Banner } from "../../../../components/Banner/Banner";
import { getPictureUrl } from "../../../../helpers/picture/getPictureUrl";
import { eventPageTourSteps } from "./eventPageTourSteps";

import "./EventPage.less";

// TODO : share on socials button
// TODO : add to calendar
// TODO : add promoter contact

export const EventPage = observer((props) => {
  const params = useParams();
  const navigate = useNavigate();
  const { event } = props;
  const [startTour, setStartTour] = useState(false);
  const [isNotValidated, setIsNotValidated] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  const keydownEventHandler = (event) => {
    const keyPressed = event.key.toLowerCase();
    if (keyPressed === "backspace" || keyPressed === "escape") {
      event.preventDefault();
      navigate(-1);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", keydownEventHandler);
    return () => {
      window.removeEventListener("keydown", keydownEventHandler);
    };
  }, []);

  const fetchEventData = async (id) => {
    if (id) {
      await spielplanStore.fetchAllBaseData();
      const eventFound = await getSingleEvents(id);
      if (userStore.isAdmin || eventFound.user.id === userStore.id) {
        setIsNotValidated(false);
      } else {
        setIsNotValidated(!eventFound.validated);
      }
      setCanEdit(userStore.isAdmin || userStore.id === eventFound.user.id);
      spielplanStore.setSelectedEvent(eventFound);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    spielplanStore.fetchTags();
    spielplanStore.fetchEventtypes();
    fetchEventData(params.id);
  }, []);

  useEffect(() => {
    if (event?.pictures.length) {
      getUrlsFromPicturePath(event.pictures);
    } else if (event?.externalPicture) {
      pageStore.setPicturesUrls([{ url: props.event.externalPicture }]);
    } else if (event) {
      pageStore.setPicturesUrls(null);
    }
  }, [event]);

  const getUrlsFromPicturePath = async (pictures) => {
    setIsLoading(true);
    const urls = await Promise.all(
      pictures.map(async (picture) => {
        return {
          id: picture.id,
          url: await getPictureUrl(`${picture}`, "events"),
        };
      }),
    );
    pageStore.setPicturesUrls(urls);
    setIsLoading(false);
  };

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);

  return (
    <>
      {event?.validated === false ? (
        <Banner
          title="This event is pending validation"
          desc="This profile is being reviewed by our team. Until then, only you can access this event page."
          id={"pendingEventBanner"}
          color="lightRed"
          closable={false}
        />
      ) : event?.private && event?.forwardable ? (
        <Banner
          title="This event is set as Private & forwardable"
          desc="Only the poeple you invited and the people this has been forwarded to, can see this event page."
          id={"privateForwardableEventBanner"}
          color="gold"
          closable={true}
        />
      ) : (
        event?.private && (
          <Banner
            title="This event is set as Private"
            desc="Only the poeple you invited can see this event page."
            id={"privateForwardableEventBanner"}
            color="gold"
            closable={true}
          />
        )
      )}

      <div className="eventpage__backgroundgradient"></div>
      {!isNotValidated && (
        <div
          className="eventpage__backgroundimage"
          style={{
            background: `url(${pageStore.picturesUrls && pageStore.picturesUrls[0]?.url}) center center / cover`,
          }}
        ></div>
      )}
      <div
        onClick={() => {
          navigate(-1);
        }}
        className={`eventpage__back link 
                  ${
                    pageStore.selectedTheme === "light"
                      ? "lightColorTheme__Text"
                      : "darkColorTheme__Text"
                  }`}
      >
        <ArrowLeftOutlined />
      </div>
      {isNotValidated ? (
        <EventPageInValidation />
      ) : (
        <div
          className={`eventpage__container 
                ${pageStore.selectedTheme === "light" ? "black" : "white"}`}
        >
          {!isLoading ? (
            <>
              <EventPageArtwork event={event} ref1={ref1} />
              <EventPageDesc
                event={event}
                canEdit={canEdit}
                ref2={ref2}
                ref3={ref3}
                ref4={ref4}
                ref5={ref5}
                ref6={ref6}
              />
            </>
          ) : (
            <div className="eventpage__spinnerContainer">
              <CustomSpinner text={`${t("general.loading")} (Events)`} />
            </div>
          )}
          <HelpButtons missingEvent={true} setStartTour={setStartTour} />
        </div>
      )}
      <Tour
        open={startTour}
        onClose={() => setStartTour(false)}
        steps={eventPageTourSteps(ref1, ref2, ref3, ref4, ref5, ref6)}
      />
    </>
  );
});
