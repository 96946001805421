import React, { useState } from "react";
import { observer } from "mobx-react";
import { Form, Modal, message, Input, Button, Col, Row, Radio } from "antd";
import { DeleteOutlined, SyncOutlined } from "@ant-design/icons";

import { useTranslation } from "react-i18next";
import { pageStore } from "../../../../../../store/pageStore/pageStore";
import { userStore } from "../../../../../../store/userStore/userStore";
import { UploadForm } from "../../../../../../components/UploadForm/UploadForm";
import { postPicture } from "../../../../../../helpers/picture/postPicture";
import { getPictureUrl } from "../../../../../../helpers/picture/getPictureUrl";
import { deletePicture } from "../../../../../../helpers/picture/deletePicture";
import { partnerStore } from "../../../../../../store/partnerStore/partnerStore";
import { profileStore } from "../../../../../../store/profileStore/profileStore";
import { addPicture } from "./addPhoto";

import "./AddPhotoForm.less";

// TODO : Add tags
// TODO : Make responsive
// TODO : Delete picture if uploaded but not validated
// TODO : Reset form on success
// TODO : fetch photos on success

export const AddPhotoForm = observer((props) => {
  const { t } = useTranslation();
  const { showModal, setShowModal, isPartner } = props;
  const [picture, setPicture] = useState(null);
  const [pictureUrl, setPictureUrl] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAdult, setIsAdult] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const [form] = Form.useForm();
  const { TextArea } = Input;

  const submitHandler = async () => {
    if (picture === null) {
      console.error("no picture uploaded");
      return;
    }
    setIsLoading(true);
    try {
      const description = form.getFieldValue("description");
      const res = await addPicture(
        picture,
        description,
        isAdult,
        isPrivate,
        isPartner ? parseInt(userStore.partnerSelected.id) : null,
      );
      if (Object.hasOwn(res, "errors")) {
        message.error(res.errors[0].message);
      } else {
        message.info(t(`profile.newPictureUploaded`));
        form.resetFields();
        setPictureUrl(null);
        setPicture(null);
        setIsAdult(false);
        setIsPrivate(false);
        setShowModal(false);
        if (isPartner) {
          partnerStore.fetchPartnerPhotos();
        } else {
          profileStore.fetchProfilePhotos();
        }
      }
    } catch (e) {
      console.error(e);
    }
    setIsLoading(false);
  };

  const pictureUploadHandler = async (file) => {
    setIsUploading(true);
    const formData = new FormData();
    formData.append("file", file);
    try {
      const res = await postPicture(file, "temp");
      if (res.path) {
        const url = await getPictureUrl(res.path, "temp");
        const isloaded = new Promise((resolve, reject) => {
          const loadImg = new Image();
          loadImg.src = url;
          loadImg.onload = () => resolve(url);
        });
        await isloaded;
        setPicture(res.path);
        setPictureUrl(url);
      }
    } catch (err) {
      message.error(t("profile.pictureUploadFail"));
      console.log(err);
    }
    setIsUploading(false);
  };

  const deletePictureHandler = async () => {
    setIsUploading(true);
    try {
      await deletePicture(picture, "temp");
      setPicture(null);
      setPictureUrl(null);
    } catch (err) {
      message.error(t("profile.pictureDeleteFail"));
      console.log(err);
    }
    setIsUploading(false);
  };

  const changeIsAdultHandler = (event) => {
    const value = parseInt(event.target.value);
    if (value === 1) {
      setIsAdult(true);
    } else {
      setIsAdult(false);
    }
  };

  const changeIsPrivateHandler = (event) => {
    const value = parseInt(event.target.value);
    if (value === 1) {
      setIsPrivate(true);
    } else {
      setIsPrivate(false);
    }
  };

  return (
    <Modal
      title={<div className="modal__title">{t(`profile.addPhoto`)}</div>}
      open={showModal}
      onCancel={() => setShowModal(false)}
      footer={null}
      centered={true}
      className={`form__modal ${pageStore.selectedTheme === "light" ? "backgroundLight" : "backgroundDark"}`}
    >
      <div className="modal__select">
        <Form
          form={form}
          name="createPartnerForm"
          onFinish={submitHandler}
          style={{ height: "100%" }}
        >
          <Row>
            <Col span={12}>
              {pictureUrl ? (
                <div
                  className="modal__pictureBackground"
                  style={{
                    background: `url(${pictureUrl})  center center / contain no-repeat`,
                    width: "220px",
                    height: "214px",
                  }}
                >
                  <div
                    className="modal__deleteAvatar"
                    onClick={deletePictureHandler}
                  >
                    <DeleteOutlined />
                  </div>
                </div>
              ) : (
                <UploadForm
                  fileUploadHandler={pictureUploadHandler}
                  isUploading={isUploading}
                  width={"220px"}
                  height={"214px"}
                  showText={false}
                />
              )}
            </Col>
            <Col span={12}>
              <Form.Item
                name="description"
                rules={[
                  {
                    required: true,
                    message: t("profile.missingPhotoDesc"),
                  },
                ]}
              >
                <TextArea
                  placeholder={t("profile.pictureShortDescription")}
                  rows={5}
                  maxLength={1024}
                  showCount
                />
              </Form.Item>

              <div style={{ paddingBottom: 8 }}>
                <Radio.Group
                  defaultValue={0}
                  buttonStyle="solid"
                  onChange={changeIsPrivateHandler}
                >
                  <Radio.Button value={0}>For all eyes</Radio.Button>
                  <Radio.Button value={1}>Private</Radio.Button>
                </Radio.Group>
              </div>
              <div style={{ paddingBottom: 8 }}>
                <Radio.Group
                  defaultValue={0}
                  buttonStyle="solid"
                  onChange={changeIsAdultHandler}
                >
                  <Radio.Button value={0}>Not explicit</Radio.Button>
                  <Radio.Button value={1}>Adult only</Radio.Button>
                </Radio.Group>
              </div>
            </Col>
          </Row>

          {/* <Form.Item
            name="tags"
            rules={[
              {
                required: true,
                message: t("profile.missingDescription"),
              },
            ]}
          >
            tags
          </Form.Item> */}

          <div className="modal__footerContainer">
            <Form.Item>
              <Button
                disabled={!picture}
                htmlType="submit"
                className={`modal__footerButtonCentered ${pageStore.selectedTheme === "light" ? "lightColorTheme__Button" : "darkColorTheme__Button"}`}
              >
                {isLoading ? <SyncOutlined spin /> : "Upload"}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </Modal>
  );
});
