import React, { useState } from "react";
import { observer } from "mobx-react";
import { Modal, Button, message, Select, Tooltip } from "antd";
import isEqual from "lodash.isequal";

import { useTranslation } from "react-i18next";
import { pageStore } from "../../../../../../store/pageStore/pageStore";
import { addTag } from "../../../../../Admin/AdminData/AdminTags/addTag";
import { profileStore } from "../../../../../../store/profileStore/profileStore";
import { partnerStore } from "../../../../../../store/partnerStore/partnerStore";
import { nameParser } from "../../../../../../helpers/dev/nameParser";
import { updateTags } from "./updateTags";
import { updatePartnerTags } from "./updatePartnerTags";

import "./EditTagsModal.less";

export const EditTagsModal = observer((props) => {
  const { t } = useTranslation();
  const { showTagsModal, setShowTagsModal, isPartner } = props;
  const [tagValue, setTagValue] = useState(
    isPartner ? partnerStore.tags : profileStore.tags,
  );
  const [hasNewTag, setHasNewTag] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const changeHandler = (value) => {
    value.map(async (tag, index) => {
      if (isNaN(tag)) {
        setHasNewTag(true);
        const dataObjectTag = {
          name: `{"en":"${tag}", "de":"${tag}"}`,
          isPartnerTag: isPartner,
          isUserTag: !isPartner,
        };
        const res = await addTag(dataObjectTag);
        value[index] = parseInt(res.id);
      }
    });
    setTagValue(value);
    if (isEqual(value, isPartner ? partnerStore.tags : profileStore.tags)) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  };

  const userTagsOptions = pageStore.tags
    .filter((tag) => tag.isUserTag)
    .map((tag) => {
      return {
        value: parseInt(tag.id),
        label: `${nameParser(tag.name, pageStore.selectedLanguage)}${!tag.validated ? ` (${t("general.pendingReview")})` : ""}`,
      };
    });

  const partnerTagsOptions = pageStore.tags
    .filter((tag) => tag.isPartnerTag)
    .map((tag) => {
      return {
        value: parseInt(tag.id),
        label: `${nameParser(tag.name, pageStore.selectedLanguage)}${!tag.validated ? ` (${t("general.pendingReview")})` : ""}`,
      };
    });

  const saveHandler = async () => {
    try {
      if (isPartner) {
        await updatePartnerTags(partnerStore.id, tagValue);
        partnerStore.setTags(tagValue);
        message.info("Partner tags updated!");
      } else {
        await updateTags(tagValue);
        profileStore.setTags(tagValue);
        message.info("User tags updated!");
      }
      if (showTagsModal === true) {
        setShowTooltip(false);
        setTimeout(() => {
          setShowTagsModal(false);
        }, 500);
      } else {
        setShowTagsModal(false);
      }
      if (hasNewTag) {
        pageStore.fetchData();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const closeHandler = () => {
    const originalValue = isPartner ? partnerStore.tags : profileStore.tags;
    if (showTooltip === true) {
      setShowTooltip(false);
      setTimeout(() => {
        setShowTagsModal(false);
      }, 500);
    } else if (originalValue === tagValue) {
      setShowTagsModal(false);
    } else {
      setShowTooltip(true);
    }
  };

  return (
    <Modal
      // TODO Translation
      title={
        <div className="modal__title">
          {isPartner ? t("profile.editPartnerTags") : t("profile.editUserTags")}
        </div>
      }
      open={showTagsModal}
      onCancel={closeHandler}
      footer={
        <div className="modal__footerContainer">
          <Tooltip
            open={showTooltip}
            title={t("general.forgetToSave")}
            placement="bottom"
          >
            <Button
              onClick={saveHandler}
              className={`modal__footerButtonCentered ${pageStore.selectedTheme === "light" ? "lightColorTheme__Button" : "darkColorTheme__Button"}`}
              modal__footerButton
              disabled={isDisabled}
            >
              {t("general.save")}
            </Button>
          </Tooltip>
        </div>
      }
      centered={true}
      className={`eventform__modal ${pageStore.selectedTheme === "light" ? "backgroundLight" : "backgroundDark"}`}
    >
      <div className="modal__select">
        <Select
          mode="tags"
          allowClear
          style={{ width: "100%" }}
          placeholder={t("eventform.pleaseSelectTags")}
          options={isPartner ? partnerTagsOptions : userTagsOptions}
          onChange={changeHandler}
          value={tagValue}
          filterOption={(inputValue, option) =>
            option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
          }
        />
      </div>
    </Modal>
  );
});
