export const priceOptions = {
  en: [
    {
      value: 0,
      label: "Online price",
    },
    {
      value: 1,
      label: "Early bird",
    },
    {
      value: 2,
      label: "At the door",
    },
  ],
  de: [
    {
      value: 0,
      label: "Online-Preis",
    },
    {
      value: 1,
      label: "Early bird",
    },
    {
      value: 2,
      label: "Abendkasse",
    },
  ],
};
