import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import { getAllPartners } from "../../Admin/AdminPartners/getAllPartners";
import { CustomSpinner } from "../../../components/CustomSpinner/CustomSpinner";
import { PartnerCard } from "./PartnerCard/PartnerCard";
import { pageStore } from "../../../store/pageStore/pageStore";

import "./Partners.less";
import nameParser from "../../../../server/lib/scrapers/helpers/nameParser";

export const Partners = observer(() => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [partners, setPartners] = useState([]);

  const fetchPartners = async () => {
    const resultPartners = await getAllPartners();
    setPartners(resultPartners);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchPartners();
  }, []);

  return isLoading ? (
    <div className="partners__spinnerContainer">
      <CustomSpinner text={`${t("general.loading")} (Partners)`} />
    </div>
  ) : (
    <div className="partners__container">
      {pageStore.partnertypes.map(partnertype => {
        const partnersFiltered = partners.filter(partner => partner.partnertype === parseInt(partnertype.id))
        return (
          <>
            {!!partnersFiltered.length &&
              <div className='partners__partnertypeTitle'>{nameParser(partnertype.name, 'en')}</div>}
            {!!partnersFiltered.length && partnersFiltered.map((partner) => {
              return (
                <div key={partner.id}>
                  <PartnerCard partner={partner} />
                </div>
              );
            }
            )}
          </>
        );
      })}
    </div>
  );
});
