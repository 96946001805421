import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import { profileStore } from "../../../../../store/profileStore/profileStore";
import { pageStore } from "../../../../../store/pageStore/pageStore";
import { authStore } from "../../../../../store/authStore/authStore";
import { partnerStore } from "../../../../../store/partnerStore/partnerStore";
import { ProfileMainTitle } from "../profileComponents/ProfileMainTitle/ProfileMainTitle";
import { AddPhotoForm } from "./AddPhotoForm/AddPhotoForm";
import { PhotoCard } from "./PhotoCard/PhotoCard";
import { getPictureUrl } from "../../../../../helpers/picture/getPictureUrl";

import "./ProfilePhotos.less";

// TODO1: use gallery overlay to show the pictures
// TODO If partner, load photo from PartnerId in store

export const ProfilePhotos = observer((props) => {
  const { thisIsMine, isPartner } = props;
  const { t } = useTranslation();
  const [showAddPictureModal, setShowAddPictureModal] = useState(false);

  const allPhotos = isPartner ? partnerStore.photos : profileStore.photos;

  const photosFormatted =
    allPhotos?.length &&
    allPhotos.map((photo, index) => {
      return (
        <PhotoCard
          key={photo.id}
          index={index}
          photo={photo}
          isPartner={isPartner}
          thisIsMine={thisIsMine}
        />
      );
    });

  // TODO Hide private picture if needed (!thisIsMine and notAllowed)
  // TODO Show blury if isAdult user has no access
  const getUrlsFromPicturePath = async () => {
    if (allPhotos?.length) {
      try {
        const urls = await Promise.all(
          allPhotos.map(async (picture) => {
            const { id, photo, isAdult, isPrivate, partnerId, description } =
              picture;
            const showPicture = isPrivate
              ? false
              : !(isAdult && !authStore.hasAccess);
            console.log();
            if (!showPicture) {
              return null;
            }
            const photoPath = `${photo}_m`;
            const bucket = partnerId ? "partners" : "users";
            const picUrl = await getPictureUrl(photoPath, bucket);
            return { id, description, url: picUrl };
          }),
        );
        pageStore.setPicturesUrls(urls);
      } catch (e) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    getUrlsFromPicturePath();
  }, [partnerStore.photos, profileStore.photos]);

  const numberOfPhotos = isPartner
    ? partnerStore.photos?.length
    : profileStore.photos?.length;

  return (
    <>
      {thisIsMine && (
        <AddPhotoForm
          showModal={showAddPictureModal}
          setShowModal={setShowAddPictureModal}
          isPartner={isPartner}
        />
      )}
      <div className="profilePhotos__container">
        <ProfileMainTitle
          title={t("profile.photos")}
          value={numberOfPhotos}
          addPhoto={true}
          thisIsMine={thisIsMine}
          showEdit={showAddPictureModal}
          setShowEdit={setShowAddPictureModal}
        />
        <div className="profilePhotos__main">
          {!numberOfPhotos ? (
            <div className="profilePhotos__empty">
              {t("profile.nothingYet")}
            </div>
          ) : (
            photosFormatted
          )}
        </div>
      </div>
    </>
  );
});
